import {useContext, useEffect, useState} from "react";
import {Hotel, UserHotelsSelectedOrAvailable} from "../../../data/BackendClasses";
import {getJsonFromBackend, postJsonToBackend} from "../../../data/network";
import {PickList, PickListChangeEvent} from "primereact/picklist";
import {Dialog} from "primereact/dialog";
import {GlobalContext} from "../../../data/GlobalContext";
import {ProgressSpinner} from "primereact/progressspinner";

export default function ManagementUserHotels(
    {
        showUserHotelsPickList,
        setShowUserHotelsPickList,
        selectedUserId,
        onClose
    }:{
        showUserHotelsPickList: boolean,
        setShowUserHotelsPickList: (show: boolean) => void,
        selectedUserId: number,
        onClose: () => void
    }
) {

    const [availableHotels, setAvailableHotels] = useState<Hotel[]>([]);
    const [selectedHotels, setSelectedHotels] = useState<Hotel[]>([]);
    const [hotelsChanged, setHotelsChanged] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const {showToastMessage} = useContext(GlobalContext);

    useEffect(() => {

        setLoading(prevState => true);

        getJsonFromBackend("/hotels/available-and-selected?userId=" + selectedUserId)
            .then((rsp: UserHotelsSelectedOrAvailable) => {
                setSelectedHotels(rsp.selected);
                setAvailableHotels(rsp.available);
                setLoading(prevState => false);
            })
            .catch(err => {
                console.log("ERR getting user hotels", err);
                setLoading(prevState => false);
                showToastMessage("ERROR", "Error getting user hotels", "error");
            })
    }, [])

    function onChange(event: PickListChangeEvent) {
        setHotelsChanged(true);
        setAvailableHotels(event.source);
        setSelectedHotels(event.target);
    };

    async function saveHotels() {
        setLoading(prevState => true);
        await postJsonToBackend("/users/update-hotels", {userId: selectedUserId, newHotels: selectedHotels});
        setLoading(prevState => false);
        onClose();
    }

    const itemTemplate = (item: Hotel) => {
        return (
            <div className="flex flex-wrap p-2 align-items-center gap-3">
                <div className="flex-1 flex flex-column gap-2">
                    <span className="font-bold">{item.name}</span>
                </div>
            </div>
        );
    };

    return <div id="management-user-roles">
        <Dialog
            header="Choose hotels"
            visible={showUserHotelsPickList}
            style={{ width: '50vw' }}
            onHide={() => setShowUserHotelsPickList(false)}
        >
            <div className="flex flex-col w-full relative">
                {loading ?
                    <ProgressSpinner/>
                    :
                    <>
                        <PickList
                            source={availableHotels}
                            target={selectedHotels}
                            onChange={onChange}
                            itemTemplate={itemTemplate}
                            filter
                            filterBy="name"
                            breakpoint="1400px"
                            sourceHeader="Available"
                            targetHeader="Selected"
                            sourceStyle={{ height: '24rem' }}
                            targetStyle={{ height: '24rem' }}
                            sourceFilterPlaceholder="Search by name"
                            targetFilterPlaceholder="Search by name"
                            showSourceControls={false}
                            showTargetControls={false}
                            pt={{
                                moveToTargetButton: {root: {className: "!bg-[#576f75] hover:!bg-[#485e63] !border-none"}},
                                moveToSourceButton: {root: {className: "!bg-[#576f75] hover:!bg-[#485e63] !border-none"}},
                                moveAllToTargetButton: {root: {className: "!bg-[#576f75] hover:!bg-[#485e63] !border-none"}},
                                moveAllToSourceButton: {root: {className: "!bg-[#576f75] hover:!bg-[#485e63] !border-none"}},
                            }}
                        />
                        <div className="w-full mt-2 flex justify-center">
                            {hotelsChanged && <button
                                onClick={saveHotels}
                                className="border p-1 px-2 rounded bg-[#576f75] hover:bg-[#485e63] text-white"
                            >
                                Done
                            </button>}
                        </div>
                    </>
                }

            </div>
        </Dialog>
    </div>

}