import {Dialog} from "primereact/dialog";
import React, {useEffect, useState} from "react";
import {Calendar, CalendarChangeEvent, CalendarDateTemplateEvent} from "primereact/calendar";
import {RoomData} from "./CloudbedsCalendarPage";
import {MultiSelect, MultiSelectChangeEvent} from "primereact/multiselect";
import {getStartDayTime, isDate} from "../../../lib/helpers";
import {Role} from "../../../data/BackendClasses";
import {useCurrentUser} from "../../../data/user";

export type BulkUpdate = {
    selectedDaysOfWeek: string[];
    dates: Date[]
}

export type Room = {
    roomId: number,
    name: string,
    linked: number
}

export default function BulkUpdateDialog(
    {
        showBulkUpdate,
        onHide,
        startDate,
        endDate,
        data,
        onApply
    }:{
        showBulkUpdate: boolean,
        onHide: () => void,
        startDate: Date,
        endDate: Date,
        data: RoomData[],
        onApply: (field: string, fieldValue: string, updates: BulkUpdate[], rooms: Room[]) => void
    })
{

    const currentUser = useCurrentUser();

    const updatableFields = currentUser.roles.includes(Role.ROCKETSTAY_MASTER) ? ["Inventory", "Multiplier", "Price", "Min stay", "Max stay", "Override"] : getUserAvailableFields();
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const [selectedUpdatableField, setSelectedUpdatableField] = useState<string>("price")
    const [selectedUpdatableFieldValue, setSelectedUpdatableFieldValue] = useState<string | undefined>("")
    const [dates, setDates] = useState<Date[]>([new Date(), getInitialEndDate()]);
    const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState<string[]>(daysOfWeek);

    const [bulkUpdates, setBulkUpdates] = useState<BulkUpdate[]>([]);

    const [err, setErr] = useState<string | undefined>(undefined);

    const [rooms, setRooms] = useState<Room[]>([]);
    const [selectedRooms, setSelectedRooms] = useState<Room[]>([]);

    const [internalStartDate, setInternalStartDate] = useState<string | Date | Date[] | undefined | null>(new Date());
    const [internalEndDate, setInternalEndDate] = useState<string | Date | Date[] | undefined | null>(getInitialEndDate());


    function getUserAvailableFields() : string[] {
        const availableFields = [];

        if(currentUser.roles.includes(Role.ROCKETSTAY_CALENDAR_PRICE_EDIT))
            availableFields.push("Price")

        if(currentUser.roles.includes(Role.ROCKETSTAY_CALENDAR_INVENTORY_EDIT))
            availableFields.push("Inventory")

        if(currentUser.roles.includes(Role.ROCKETSTAY_CALENDAR_MULTIPLIER_EDIT))
            availableFields.push("Multiplier")

        if(currentUser.roles.includes(Role.ROCKETSTAY_CALENDAR_MINSTAY_EDIT))
            availableFields.push("Min stay")

        if(currentUser.roles.includes(Role.ROCKETSTAY_CALENDAR_MAXSTAY_EDIT))
            availableFields.push("Max stay")

        if(currentUser.roles.includes(Role.ROCKETSTAY_CALENDAR_OVERRIDE_EDIT))
            availableFields.push("Override")

        return availableFields;
    }

    useEffect(() => {
        setRooms(prevState => data.map(roomData => ({roomId: roomData.roomId, name: roomData.name, linked: roomData.linked})));
    }, [])

    function toggleSelectedDayOfWeek(e: any) {
        if(selectedDaysOfWeek.includes(e.target.value)){
            setSelectedDaysOfWeek(prevState => prevState.filter((day) => day !== e.target.value))
        } else {
            setSelectedDaysOfWeek(prevState => [...prevState, e.target.value])
        }
    }

    function addNewRange() {
        if(isDateArray(dates)){
            if(arrayDoesNotContainDates(dates, bulkUpdates))
            {
                setBulkUpdates(prevState => [...prevState, {selectedDaysOfWeek: selectedDaysOfWeek, dates: getDatesBetween(dates[0], dates[1])}]);
                resetFields();
            }
            else {
                setErr("This range is already contained in the selected ranges");
            }
        } else {
            console.log("!!! is not date array")
        }
    }


    function arrayDoesNotContainDates(dates: Date[], bulkUpdates: BulkUpdate[]){

        console.log("ARRAY DOES NOT CONTAIN DATES ", dates)

        for(const bulkUpdate of bulkUpdates){
            if((dates[0].getTime() >= bulkUpdate.dates[0].getTime() && dates[0].getTime() <= bulkUpdate.dates[bulkUpdate.dates.length-1].getTime()) || (dates[1].getTime() >= bulkUpdate.dates[0].getTime() && dates[1].getTime() <= bulkUpdate.dates[bulkUpdate.dates.length-1].getTime())){
                return false;
            }
        }

        return true;
    }

    function resetFields() {
        setDates([new Date(), getInitialEndDate()]);
        setSelectedDaysOfWeek(daysOfWeek);
        setErr(prevState => undefined);
    }

    function getDatesBetween(startDate: Date, endDate: Date): Date[] {
        const dates: Date[] = [];

        let currentDate = new Date(startDate.getTime());

        while (currentDate.getTime() <= endDate.getTime()) {
            if(selectedDaysOfWeek.includes(daysOfWeek[currentDate.getDay()])){
                dates.push(new Date(currentDate));
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }

        if(getStartDayTime(currentDate)-endDate.getTime() == 1){
            dates.push(new Date(currentDate));
        }

        return dates;
    }

    function isDateArray(value: string | Date | Date[] | null | undefined): value is Date[] {
        return Array.isArray(value) && value.every((item) => true);
    }

    function applyBulkUpdate(){
        selectedUpdatableFieldValue && onApply(selectedUpdatableField, selectedUpdatableFieldValue, bulkUpdates, selectedRooms);
    }

    function changeStartDate(e: CalendarChangeEvent) {
        setErr(prevState => undefined)
        setDates(prevState => {
            if(isDate(e.value)){
                return[e.value, prevState[1]]
            } else {
                return prevState;
            }
        })
    }

    function changeEndDate(e: CalendarChangeEvent) {
        setErr(prevState => undefined)
        setDates(prevState => {
            if(isDate(e.value)){
                return[prevState[0], e.value]
            } else {
                return prevState;
            }
        })
    }

    function getInitialEndDate() : Date {
        const today = new Date();
        return new Date(today.setDate(today.getDate() + 1));
    }

    const customDateTemplate = (date: CalendarDateTemplateEvent) => {
        const jsDate = new Date(date.year, date.month, date.day);

        const dayOfWeek = jsDate.getDay();

        if(dayOfWeek === 6 || dayOfWeek === 0)
            return <div className={"weekend"}>{date.day}</div>;
        else
            return date.day;
    };

    function changeValue(newValue: string) {
        if(selectedUpdatableField == "inventory" || selectedUpdatableField == "min stay" || selectedUpdatableField == "max stay"){
            const newValueNumber = parseInt(newValue);
            if (!isNaN(newValueNumber)) {
                setSelectedUpdatableFieldValue(""+newValueNumber);
            }
        } else
            setSelectedUpdatableFieldValue(prevState => newValue)
    }

    return <>
        <Dialog
            header={`Bulk update`}
            visible={showBulkUpdate}
            pt={{
                root: {className: "w-full md:w-fit"}
            }}
            onHide={onHide}
            style={{ minWidth: 'fit-content', minHeight: 'fit-content'}}
        >
            <div className="flex flex-col w-full gap-3">

                <div className="flex flex-col sm:flex-row w-full gap-2 text-xs sm:text-base justify-center">
                    <div className="flex flex-row w-1/2 gap-2">
                        <label htmlFor="selectUpdatableField" className="font-medium my-auto">Set</label>
                        <select
                            name="selectUpdatableField"
                            className="custom-form h-fit w-full"
                            value={selectedUpdatableField}
                            onChange={(e) => {setSelectedUpdatableField(e.target.value); setSelectedUpdatableFieldValue(prevState => "")}}
                        >
                            {updatableFields.map(
                                field => {
                                    return <option key={field} value={field}>{field}</option>
                                }
                            )}
                        </select>
                    </div>

                    {selectedUpdatableField == "Override" ? <div className="flex flex-row w-1/2 gap-2">
                            <label htmlFor="selectUpdatableFieldValue" className="font-medium my-auto">To</label>
                            <select
                                name="selectUpdatableFieldValue"
                                className="custom-form h-fit w-full"
                                onChange={(e) => setSelectedUpdatableFieldValue(e.target.value)}
                                value={selectedUpdatableFieldValue}
                            >
                                <option value="">Select a value</option>
                                <option value="none">Open</option>
                                <option value="blackout">Blackout</option>
                                <option value="noCheckIn">No check in</option>
                                <option value="noCheckOut">No check out</option>
                                <option value="noCheckInOrCheckOut">No check in or out</option>
                                <option value="exception">Exception</option>
                            </select>
                        </div>
                        :
                        <div className="flex flex-row w-1/2 gap-2">
                            <label htmlFor="selectUpdatableFieldValue" className="font-medium my-auto">To</label>
                            <input
                                type="text"
                                name="selectUpdatableFieldValue"
                                className="custom-form mt-auto w-full"
                                value={selectedUpdatableFieldValue}
                                onChange={(e) => changeValue(e.target.value)}
                            />
                        </div>
                    }

                </div>

                <div className="flex flex-row w-full mt-2">
                    <div key="div-before" className="w-full flex mr-1">
                        <hr key="hr-before" className="w-full bg-gray-300 my-auto"/>
                    </div>
                    <div key="selected-div" className="w-fit content-center h-full text-center text-sm italic text-gray-500 whitespace-nowrap">
                        Select date intervals
                    </div>
                    <div key="div-after" className="w-full flex ml-1">
                        <hr key="hr-after" className="w-full bg-gray-300 my-auto"/>
                    </div>
                </div>

                <div className="flex flex-col gap-1 sm:gap-1 sm:flex-row mt-2 w-full justify-center">
                    <div className="flex flex-col">
                        <label className="font-medium" htmlFor="start-date">Start Date</label>
                        <Calendar
                            id="calendar-24h"
                            value={dates[0]}
                            onChange={(e) => changeStartDate(e)}
                            showIcon
                            showButtonBar
                            view="date"
                            // minDate={getMinStartDate()}
                            minDate={new Date()}
                            dateTemplate={customDateTemplate}
                            pt={{
                                day:{className: "!m-[1px] !p-[1px]"},
                                container: {className: "!p-[2px]"},
                                dropdownButton: {
                                    root: {className: "!bg-[#576f75] !border-[#576f75] focus:!shadow-none"}
                                }
                            }}
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="font-medium" htmlFor="end-date">End Date</label>
                        <Calendar
                            id="calendar-24h"
                            value={dates[1]}
                            onChange={(e) => changeEndDate(e)}
                            showIcon
                            showButtonBar
                            minDate={startDate}
                            view="date"
                            dateTemplate={customDateTemplate}
                            pt={{
                                day:{className: "!m-[1px] !p-[1px]"},
                                container: {className: "!p-[2px]"},
                                dropdownButton: {
                                    root: {className: "!bg-[#576f75] !border-[#576f75] focus:!shadow-none"}
                                }
                            }}
                        />
                    </div>
                </div>

                <div className="flex flex-row w-full gap-1 sm:ml-2 mx-auto justify-center">
                    {daysOfWeek.map(day =>
                        <button
                            key={day}
                            value={day}
                            className={`${selectedDaysOfWeek.includes(day) ? "text-white" : "text-black"} py-2 px-3 ${selectedDaysOfWeek.includes(day) ? "bg-[#576f75] hover:bg-[#485e63] hover:text-white" : "bg-white"} border border-[#576f75] rounded  mt-auto text-xs sm:text-base`}
                            onClick={toggleSelectedDayOfWeek}
                        >
                            {day}
                        </button>
                    )}
                </div>

                {isDate(internalStartDate) && isDate(internalEndDate) && (undefined == err) && isDateArray(dates) && (dates[0] <= dates[1]) && <div className="flex flex-col">
                    <button
                        className={"text-white py-2 px-3 bg-[#576f75] rounded hover:text-white hover:bg-[#485e63] mx-auto mt-3 sm:text-sm w-fit"}
                        onClick={addNewRange}
                    >
                        Add
                    </button>
                </div>}

                {err && <div className="w-full text-sm text-red-500 mt-1">{err}</div>}

                {bulkUpdates.length > 0 && <div className="flex flex-row w-full mt-2">
                    <div key="div-before" className="w-full flex mr-1">
                        <hr key="hr-before" className="w-full bg-gray-300 my-auto"/>
                    </div>
                    <div key="selected-div" className="w-fit content-center h-full text-center text-sm italic text-gray-500 whitespace-nowrap">
                        Selected date intervals
                    </div>
                    <div key="div-after" className="w-full flex ml-1">
                        <hr key="hr-after" className="w-full bg-gray-300 my-auto"/>
                    </div>
                </div>}

                {bulkUpdates.length > 0 && <div className="mt-2 flex flex-col gap-2 ">

                    {bulkUpdates.map((bulkUpdate, index)=> <div key={index}>
                        <BulkUpdateRecord
                            key={index}
                            bulkUpdate={bulkUpdate}
                            onRemove={
                                () => setBulkUpdates((prevBulkUpdates) => {
                                    const newBulkUpdates = [...prevBulkUpdates];
                                    newBulkUpdates.splice(index, 1);
                                    return newBulkUpdates;
                                })
                            }
                            index={index}
                        />
                    </div>)}

                </div>}

                {bulkUpdates.length > 0 && <>
                    {/*<div className="w-full flex ml-1">*/}
                    {/*    <hr className="w-full bg-gray-300 mt-3"/>*/}
                    {/*</div>*/}

                    <div className="flex flex-row w-full mt-2">
                        <div key="div-before" className="w-full flex mr-1">
                            <hr key="hr-before" className="w-full bg-gray-300 my-auto"/>
                        </div>
                        <div key="selected-div" className="w-fit content-center h-full text-center text-sm italic text-gray-500 whitespace-nowrap">
                            Selected rooms
                        </div>
                        <div key="div-after" className="w-full flex ml-1">
                            <hr key="hr-after" className="w-full bg-gray-300 my-auto"/>
                        </div>
                    </div>

                    <div className="mt-2 w-1/2 max-w-[400px] mx-auto">
                        <MultiSelect
                            value={selectedRooms}
                            onChange={(e: MultiSelectChangeEvent) => {
                                setSelectedRooms(e.value)}
                            }
                            options={rooms}
                            optionLabel="name"
                            placeholder="Select Rooms"
                            maxSelectedLabels={3}
                            className="w-full"
                        />
                    </div>

                    <div className="flex flex-row justify-center mt-2 gap-2">
                        {selectedUpdatableFieldValue && bulkUpdates.length > 0 && selectedRooms.length > 0  && <button
                            className={"text-white py-2 px-3 bg-[#576f75] rounded hover:text-white hover:bg-[#485e63] mt-auto text-xs sm:text-sm"}
                            onClick={applyBulkUpdate}
                        >
                            Save Draft
                        </button>}
                        <button
                            className={"text-white py-2 px-3 bg-[#576f75] rounded hover:text-white hover:bg-[#485e63] mt-auto text-xs sm:text-sm"}
                            onClick={onHide}
                        >
                            Cancel
                        </button>
                    </div>

                </>}
            </div>
        </Dialog>
    </>


    function BulkUpdateRecord({bulkUpdate, onRemove, index}:{bulkUpdate: BulkUpdate, onRemove: () => void, index: number}) {
        return <div className="flex flex-col gap-2 sm:flex-row sm:gap-0">
            <Calendar
                key={"calendar_"+index}
                className="w-full !max-w-[300px]"
                name="rangeCalendar"
                disabled
                value={[bulkUpdate.dates[0], bulkUpdate.dates[bulkUpdate.dates.length - 1]]}
                readOnlyInput={true}
                // onChange={(e) => setDates(e.value)}
                selectionMode="range"
                pt={{
                    day:{className: "!m-[1px] !p-[1px] !w-4 !h-4"},
                    container: {className: "!p-2"}
                }}

            />

            <div className="flex flex-row gap-1 sm:ml-2">
                {daysOfWeek.map(day =>
                    <button
                        key={day+"_"+index}
                        value={day}
                        className={`${bulkUpdate.selectedDaysOfWeek.includes(day) ? "text-white" : "text-black"} py-2 px-3 ${bulkUpdate.selectedDaysOfWeek.includes(day) ? "bg-[#576f75]" : "bg-white"} border border-[#576f75] rounded mt-auto text-xs sm:text-base`}
                        disabled
                    >
                        {day}
                    </button>
                )}
            </div>

            <span
                className="my-auto text-red-500 ml-1 text-sm hover:cursor-pointer hover:text-red-700"
                onClick={onRemove}
            >
                Remove
            </span>
        </div>
    }

}