import {Sidebar} from "primereact/sidebar";
import React, {useContext, useState} from "react";
import {useCurrentUser} from "../data/user";
import {Link, matchPath, useLocation} from "react-router-dom";
import {Role} from "../data/BackendClasses";
import {AnimatePresence, motion} from "framer-motion";
import {GlobalContext} from "../data/GlobalContext";


export default function CustomSidebar({sidebarVisible, setSidebarVisible}:{sidebarVisible: boolean, setSidebarVisible: (visible: boolean) => void}) {
    const location = useLocation();
    const currentUser = useCurrentUser();

    const [managementExpanded, setManagementExpanded] = useState<boolean>(false);
    const [logsExpanded, setLogsExpanded] = useState<boolean>(false);
    const [siteminderExpanded, setSiteminderExpanded] = useState<boolean>(false);
    const [reviewsExpanded, setReviewsExpanded] = useState<boolean>(false);
    const [cloudbedsExpanded, setCloudbedsExpanded] = useState<boolean>(false);
    const [derbysoftExpanded, setDerbysoftExpanded] = useState<boolean>(false);

    const {askBeforeLeavingCalendar, setAskBeforeLeavingCalendar} = useContext(GlobalContext);

    function pathBeginsWith(path: string) {
        return matchPath(
            {
                path: path,
                end: true
            },
            location.pathname
        )
    }

    function isCurrentPath(path: string){
        const isCurrentPath = pathBeginsWith(path);
        return isCurrentPath ? 'text-[#4f959d]' : ''
    }

    return <Sidebar visible={sidebarVisible} onHide={() => setSidebarVisible(false)} className="!w-fit" pt={{content: {className: "!px-1"}}}>
        <div className="flex flex-col gap-1 font-normal !text-[#001238]">
            <span className={`text-lg mt-2 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/")}`}>
                <Link to="/"
                      onClick={(e) => {
                          if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                              if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                  setSidebarVisible(false)
                                  setAskBeforeLeavingCalendar(false);
                              } else {
                                  e.preventDefault();
                              }
                      }}
                      className="flex flex-row"
                >
                    <i className="fa-solid fa-home fa-fw mr-2 my-auto"/>
                    Home
                </Link>
            </span>
            {currentUser.roles.includes(Role.ADMIN) &&<>
                <motion.header
                    className="text-lg mt-2 hover:cursor-pointer px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md flex flex-row"
                    onClick={() => setManagementExpanded((managementExpanded) => !managementExpanded)}
                >
                    <i className="fa-solid fa-cog fa-fw mr-2 my-auto"/>
                    Management
                    <i className={`fa-solid fa-caret-${managementExpanded ? "up" : "down"} ml-2 my-auto`}/>
                </motion.header>

                <AnimatePresence initial={false}>
                    {managementExpanded && <motion.section
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        className="flex flex-col"
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                        <motion.div
                            variants={{ collapsed: { opacity: 0 }, open: { opacity: 1 } }}
                            transition={{ duration: 0.5 }}
                            className="flex flex-col"
                        >
                            <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/management-users")}`}>
                                <Link
                                    to="/management-users"
                                    onClick={(e) => {
                                        if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                            if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                setSidebarVisible(false)
                                                setAskBeforeLeavingCalendar(false);
                                            } else {
                                                e.preventDefault();
                                            }
                                    }}
                                    className="flex flex-row"
                                >
                                    <i className="fa-solid fa-user fa-fw mr-2"/>
                                    Users
                                </Link>
                            </span>
                                <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/management-hotels")}`}>
                                <Link to="/management-hotels"
                                      onClick={(e) => {
                                          if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                              if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                  setSidebarVisible(false)
                                                  setAskBeforeLeavingCalendar(false);
                                              } else {
                                                  e.preventDefault();
                                              }
                                      }}
                                      className="flex flex-row">
                                    <i className="fa-solid fa-hotel fa-fw mr-2"/>
                                    Hotels
                                </Link>
                            </span>
                        </motion.div>

                    </motion.section>}
                </AnimatePresence>
            </>}

            {(currentUser.roles.includes(Role.TRANSACTION_DEFAULT) || currentUser.roles.includes(Role.TRANSACTION_GLOBAL)) &&<span className={`text-lg mt-2 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/transaction-history")}`}>
                <Link to="/transaction-history"
                      onClick={(e) => {
                          if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                              if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                  setSidebarVisible(false)
                                  setAskBeforeLeavingCalendar(false);
                              } else {
                                  e.preventDefault();
                              }
                      }}
                      className="flex flex-row"
                >
                    <i className="fa-solid fa-file-arrow-down fa-fw mr-2 my-auto"/>
                    Transaction History
                </Link>
            </span>}

            <span className={`text-lg mt-2 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/banking-security-code")}`}>
                <Link to="/banking-security-code"
                      onClick={(e) => {
                          if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                              if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                  setSidebarVisible(false)
                                  setAskBeforeLeavingCalendar(false);
                              } else {
                                  e.preventDefault();
                              }
                      }}
                      className="flex flex-row"
                >
                    <i className="fa-solid fa-building-columns fa-fw mr-2 my-auto"/>
                    Banking Security Code
                </Link>
            </span>

            {currentUser.roles.includes(Role.CHAT) &&<span className={`text-lg mt-2 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/chat")}`}>
                <Link to="/chat"
                      onClick={(e) => {
                          if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                              if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                  setSidebarVisible(false)
                                  setAskBeforeLeavingCalendar(false);
                              } else {
                                  e.preventDefault();
                              }
                      }}
                      className="flex flex-row"
                >
                    <i className="fa-solid fa-comment-dots fa-fw mr-2 my-auto"/>
                    Chat
                </Link>
            </span>}

            {currentUser.roles.includes(Role.BEDS24_INFO) &&<span className={`text-lg mt-2 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/low-inventory-scanner")}`}>
                <Link to="/low-inventory-scanner"
                      onClick={(e) => {
                          if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                              if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                  setSidebarVisible(false)
                                  setAskBeforeLeavingCalendar(false);
                              } else {
                                  e.preventDefault();
                              }
                      }}
                      className="flex flex-row"
                >
                    <i className="fa-solid fa-boxes-stacked fa-fw mr-2 my-auto"/>
                    Low Inventory Scanner
                </Link>
            </span>}

            {(currentUser.roles.includes(Role.SUPERVISOR) || currentUser.roles.includes(Role.BEDS24_INFO) || currentUser.roles.includes(Role.ADMIN)) &&<>
                <motion.header
                    className="text-lg mt-2 hover:cursor-pointer px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md flex flex-row"
                    onClick={() => setLogsExpanded((logsExpanded) => !logsExpanded)}
                >
                    <i className="fa-solid fa-file-waveform fa-fw mr-2 my-auto"/>
                    Logs
                    <i className={`fa-solid fa-caret-${logsExpanded ? "up" : "down"} ml-2 my-auto`}/>
                </motion.header>
                <AnimatePresence initial={false}>
                    {logsExpanded && <motion.section
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        className="flex flex-col"
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                        <motion.div
                            variants={{ collapsed: { opacity: 0 }, open: { opacity: 1 } }}
                            transition={{ duration: 0.98 }}
                            className="flex flex-col"
                        >

                            {(currentUser.roles.includes(Role.SUPERVISOR) || currentUser.roles.includes(Role.BEDS24_INFO)) && <>
                                <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/supervising")}`}>
                                    <Link to="/supervising"
                                          onClick={(e) => {
                                              if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                                  if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                      setSidebarVisible(false)
                                                      setAskBeforeLeavingCalendar(false);
                                                  } else {
                                                      e.preventDefault();
                                                  }
                                          }}
                                          className="flex flex-row"
                                    >
                                        <i className="fa-solid fa-file-waveform fa-fw mr-2"/>
                                        Supervising
                                    </Link>
                                </span>
                                <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/drop-down")}`}>
                                    <Link to="/drop-down"
                                          onClick={(e) => {
                                              if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                                  if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                      setSidebarVisible(false)
                                                      setAskBeforeLeavingCalendar(false);
                                                  } else {
                                                      e.preventDefault();
                                                  }
                                          }}
                                          className="flex flex-row"
                                    >
                                        <i className="fa-solid fa-circle-info fa-fw mr-2"/>
                                        Drop Down
                                    </Link>
                                </span>
                                <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/internal-logs")}`}>
                                    <Link to="/internal-logs"
                                          onClick={(e) => {
                                              if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                                  if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                      setSidebarVisible(false)
                                                      setAskBeforeLeavingCalendar(false);
                                                  } else {
                                                      e.preventDefault();
                                                  }
                                          }}
                                          className="flex flex-row"
                                    >
                                        <i className="fa-solid fa-circle-info fa-fw mr-2"/>
                                        Internal Logs
                                    </Link>
                                </span>
                                    <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/internal-notes")}`}>
                                    <Link to="/internal-notes"
                                          onClick={(e) => {
                                              if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                                  if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                      setSidebarVisible(false)
                                                      setAskBeforeLeavingCalendar(false);
                                                  } else {
                                                      e.preventDefault();
                                                  }
                                          }}
                                          className="flex flex-row"
                                    >
                                        <i className="fa-solid fa-circle-info fa-fw mr-2"/>
                                        Internal Notes
                                    </Link>
                                </span>
                                <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/transaction-logs")}`}>
                                    <Link to="/transaction-logs"
                                          onClick={(e) => {
                                              if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                                  if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                      setSidebarVisible(false)
                                                      setAskBeforeLeavingCalendar(false);
                                                  } else {
                                                      e.preventDefault();
                                                  }
                                          }}
                                          className="flex flex-row"
                                    >
                                        <i className="fa-solid fa-file-waveform fa-fw mr-2"/>
                                        Transaction Logs
                                    </Link>
                                </span>
                            </>}

                            {currentUser.roles.includes(Role.ADMIN) && <>
                                <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/csv-loader")}`}>
                                    <Link to="/csv-loader"
                                          onClick={(e) => {
                                              if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                                  if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                      setSidebarVisible(false)
                                                      setAskBeforeLeavingCalendar(false);
                                                  } else {
                                                      e.preventDefault();
                                                  }
                                          }}
                                          className="flex flex-row"
                                    >
                                        <i className="fa-solid fa-file-upload fa-fw mr-2"/>
                                        Csv Loader
                                    </Link>
                                </span>
                            </>}

                            {currentUser.roles.includes(Role.ADMIN) && <>
                                <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/extract-base-price")}`}>
                                    <Link to="/extract-base-price"
                                          onClick={(e) => {
                                              if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                                  if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                      setSidebarVisible(false)
                                                      setAskBeforeLeavingCalendar(false);
                                                  } else {
                                                      e.preventDefault();
                                                  }
                                          }}
                                          className="flex flex-row"
                                    >
                                        <i className="fa-solid fa-file-upload fa-fw mr-2"/>
                                        Extract Base Price
                                    </Link>
                                </span>
                            </>}

                        </motion.div>
                    </motion.section>}
                </AnimatePresence>
            </>}

            {currentUser.roles.includes(Role.REVIEW) &&<>
                <motion.header
                    className="text-lg mt-2 hover:cursor-pointer px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md"
                    onClick={() => setReviewsExpanded((reviewsExpanded) => !reviewsExpanded)}
                >
                    <i className="fa-solid fa-file-waveform fa-fw mr-2"/>
                    Reviews
                    <i className={`fa-solid fa-caret-${reviewsExpanded ? "up" : "down"} ml-2`}/>
                </motion.header>
                <AnimatePresence initial={false}>
                    {reviewsExpanded && <motion.section
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        className="flex flex-col"
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                        <motion.div
                            variants={{ collapsed: { opacity: 0 }, open: { opacity: 1 } }}
                            transition={{ duration: 0.98 }}
                            className="flex flex-col"
                        >
                            <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/reviews-hotel")}`}>
                                    <Link to="/reviews-hotel"
                                          onClick={(e) => {
                                              if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                                  if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                      setSidebarVisible(false)
                                                      setAskBeforeLeavingCalendar(false);
                                                  } else {
                                                      e.preventDefault();
                                                  }
                                          }}
                                          className="flex flex-row"
                                    >
                                        <i className="fa-solid fa-hotel fa-fw mr-2"/>
                                        Reviews Hotel
                                    </Link>
                                </span>
                            <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/reviews-hotel-public")}`}>
                                <Link to="/reviews-hotel-public"
                                      onClick={(e) => {
                                          if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                              if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                  setSidebarVisible(false)
                                                  setAskBeforeLeavingCalendar(false);
                                              } else {
                                                  e.preventDefault();
                                              }
                                      }}
                                      className="flex flex-row"
                                >
                                    <i className="fa-solid fa-hotel fa-fw mr-2"/>
                                    Reviews Hotel Public
                                </Link>
                            </span>
                        </motion.div>
                    </motion.section>}
                </AnimatePresence>
            </>}

            {currentUser.roles.includes(Role.SITEMINDER) && <>
                <motion.header
                    className="text-lg mt-2 hover:cursor-pointer px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md"
                    onClick={() => setSiteminderExpanded((siteminderExpanded) => !siteminderExpanded)}
                >
                    <i className="fa-solid fa-file-waveform fa-fw mr-2"/>
                    Siteminder
                    <i className={`fa-solid fa-caret-${siteminderExpanded ? "up" : "down"} ml-2`}/>
                </motion.header>
                <AnimatePresence initial={false}>
                    {siteminderExpanded && <motion.section
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        className="flex flex-col"
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                        <motion.div
                            variants={{ collapsed: { opacity: 0 }, open: { opacity: 1 } }}
                            transition={{ duration: 0.98 }}
                            className="flex flex-col"
                        >
                            <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/hotels-spreadsheet")}`}>
                                <Link to="/hotels-spreadsheet"
                                      onClick={(e) => {
                                          if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                              if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                  setSidebarVisible(false)
                                                  setAskBeforeLeavingCalendar(false);
                                              } else {
                                                  e.preventDefault();
                                              }
                                      }}
                                      className="flex flex-row"
                                >
                                    <i className="fa-solid fa-hotel fa-fw mr-2"/>
                                    Siteminder Hotels
                                </Link>
                            </span>
                                <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/siteminder-history")}`}>
                                <Link to="/siteminder-history"
                                      onClick={(e) => {
                                          if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                              if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                  setSidebarVisible(false)
                                                  setAskBeforeLeavingCalendar(false);
                                              } else {
                                                  e.preventDefault();
                                              }
                                      }}
                                      className="flex flex-row"
                                >
                                    <i className="fa-solid fa-file-waveform fa-fw mr-2"/>
                                    Siteminder Bookings
                                </Link>
                            </span>
                            <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/siteminder-receiver")}`}>
                                <Link to="/siteminder-receiver"
                                      onClick={(e) => {
                                          if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                              if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                  setSidebarVisible(false)
                                                  setAskBeforeLeavingCalendar(false);
                                              } else {
                                                  e.preventDefault();
                                              }
                                      }}
                                      className="flex flex-row"
                                >
                                    <i className="fa-solid fa-circle-info fa-fw mr-2"/>
                                    Siteminder Receiver Bookings
                                </Link>
                            </span>
                            <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/siteminder-receiver-history")}`}>
                                <Link to="/siteminder-receiver-history"
                                      onClick={(e) => {
                                          if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                              if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                  setSidebarVisible(false)
                                                  setAskBeforeLeavingCalendar(false);
                                              } else {
                                                  e.preventDefault();
                                              }
                                      }}
                                      className="flex flex-row"
                                >
                                    <i className="fa-solid fa-circle-info fa-fw mr-2"/>
                                    Siteminder Receiver Inventory
                                </Link>
                            </span>
                            <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/siteminder-cancellation")}`}>
                                <Link to="/siteminder-cancellation"
                                      onClick={(e) => {
                                          if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                              if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                  setSidebarVisible(false)
                                                  setAskBeforeLeavingCalendar(false);
                                              } else {
                                                  e.preventDefault();
                                              }
                                      }}
                                      className="flex flex-row"
                                >
                                    <i className="fa-solid fa-circle-info fa-fw mr-2"/>
                                    Siteminder Booking Cancellation
                                </Link>
                            </span>
                        </motion.div>
                    </motion.section>}
                </AnimatePresence>
            </>}

            {currentUser.roles.includes(Role.CANCELLATION) &&<>
                <span className={`text-lg mt-2 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/cancellation")}`}>
                    <Link to="/cancellation"
                          onClick={(e) => {
                              if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                  if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                      setSidebarVisible(false)
                                      setAskBeforeLeavingCalendar(false);
                                  } else {
                                      e.preventDefault();
                                  }
                          }}
                          className="flex flex-row"
                    >
                        <i className="fa-solid fa-cloud-arrow-up fa-fw mr-2"/>
                        Cancellation Airbnb Bookings
                    </Link>
                </span>
            </>}

            {(currentUser.roles.includes(Role.ROCKETSTAY_MASTER) || currentUser.roles.includes(Role.ROCKETSTAY_CALENDAR) || currentUser.roles.includes(Role.ADMIN) || currentUser.roles.includes(Role.ROCKETSTAY_LOGS) || currentUser.roles.includes(Role.ROCKETSTAY_REPORTS) || currentUser.roles.includes(Role.ROCKETSTAY_RESERVATIONS)) && <>
                <motion.header
                    className="text-lg mt-2 hover:cursor-pointer px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md"
                    onClick={() => setCloudbedsExpanded((cloudbedsExpanded) => !cloudbedsExpanded)}
                >
                    <i className="fa-solid fa-cloud fa-fw mr-2"/>
                    Calendar & Reservations
                    <i className={`fa-solid fa-caret-${cloudbedsExpanded ? "up" : "down"} ml-2`}/>
                </motion.header>

                <AnimatePresence initial={false}>
                    {cloudbedsExpanded && <motion.section
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        className="flex flex-col"
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >

                        <motion.div
                            variants={{ collapsed: { opacity: 0 }, open: { opacity: 1 } }}
                            transition={{ duration: 0.98 }}
                            className="flex flex-col"
                        >
                            {(currentUser.roles.includes(Role.ROCKETSTAY_MASTER) || currentUser.roles.includes(Role.ROCKETSTAY_CALENDAR)) && <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/cloudbeds-calendar")}`}>
                                <Link to="/cloudbeds-calendar"
                                      onClick={(e) => {
                                          if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                              if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                  setSidebarVisible(false)
                                                  setAskBeforeLeavingCalendar(false);
                                              } else {
                                                  e.preventDefault();
                                              }
                                      }}
                                      className="flex flex-row"
                                >
                                    <i className="fa-solid fa-calendar fa-fw mr-2"/>
                                    Details Calendar
                                </Link>
                            </span>}

                            {(currentUser.roles.includes(Role.ROCKETSTAY_MASTER) || currentUser.roles.includes(Role.ROCKETSTAY_RESERVATIONS))  && <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/cloudbeds-bookings")}`}>
                                <Link to="/cloudbeds-bookings"
                                      onClick={(e) => {
                                          if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                              if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                  setSidebarVisible(false)
                                                  setAskBeforeLeavingCalendar(false);
                                              } else {
                                                  e.preventDefault();
                                              }
                                      }}
                                      className="flex flex-row"
                                >
                                    <i className="fa-solid fa-bed fa-fw mr-2"/>
                                    Reservations
                                </Link>
                            </span>}

                            {(currentUser.roles.includes(Role.ADMIN) || currentUser.roles.includes(Role.ROCKETSTAY_LOGS)) && <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/cloudbeds-calendar-logs")}`}>
                                <Link to="/cloudbeds-calendar-logs"
                                      onClick={(e) => {
                                          if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                              if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                  setSidebarVisible(false)
                                                  setAskBeforeLeavingCalendar(false);
                                              } else {
                                                  e.preventDefault();
                                              }
                                      }}
                                      className="flex flex-row"
                                >
                                    <i className="fa-solid fa-file-waveform fa-fw mr-2"/>
                                    Logs
                                </Link>
                            </span>}

                            {(currentUser.roles.includes(Role.ROCKETSTAY_MASTER) || currentUser.roles.includes(Role.ROCKETSTAY_REPORTS)) && <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/cloudbeds-reports")}`}>
                                <Link to="/cloudbeds-reports"
                                      onClick={(e) => {
                                          if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                              if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                  setSidebarVisible(false)
                                                  setAskBeforeLeavingCalendar(false);
                                              } else {
                                                  e.preventDefault();
                                              }
                                      }}
                                      className="flex flex-row"
                                >
                                    <i className="fa-solid fa-box-archive fa-fw mr-2"/>
                                    Reports
                                </Link>
                            </span>}

                        </motion.div>
                    </motion.section>}
                </AnimatePresence>
            </>}

            {currentUser.roles.includes(Role.SITEMINDER) && <>
                <motion.header
                    className="text-lg mt-2 hover:cursor-pointer px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md"
                    onClick={() => setDerbysoftExpanded((derbysoftExpanded) => !derbysoftExpanded)}
                >
                    <i className="fa-solid fa-file-waveform fa-fw mr-2"/>
                    Derbysoft
                    <i className={`fa-solid fa-caret-${derbysoftExpanded ? "up" : "down"} ml-2`}/>
                </motion.header>
                <AnimatePresence initial={false}>
                    {derbysoftExpanded && <motion.section
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        className="flex flex-col"
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                        <motion.div
                            variants={{ collapsed: { opacity: 0 }, open: { opacity: 1 } }}
                            transition={{ duration: 0.98 }}
                            className="flex flex-col"
                        >
                            <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/derbysoft-hotels-spreadsheet")}`}>
                                <Link to="/derbysoft-hotels-spreadsheet"
                                      onClick={(e) => {
                                          if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                              if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                  setSidebarVisible(false)
                                                  setAskBeforeLeavingCalendar(false);
                                              } else {
                                                  e.preventDefault();
                                              }
                                      }}
                                      className="flex flex-row"
                                >
                                    <i className="fa-solid fa-hotel fa-fw mr-2"/>
                                    Derbysoft Hotels
                                </Link>
                            </span>

                            <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/derbysoft-history")}`}>
                                <Link to="/derbysoft-history"
                                      onClick={(e) => {
                                          if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                              if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                  setSidebarVisible(false)
                                                  setAskBeforeLeavingCalendar(false);
                                              } else {
                                                  e.preventDefault();
                                              }
                                      }}
                                      className="flex flex-row"
                                >
                                    <i className="fa-solid fa-hotel fa-fw mr-2"/>
                                    Derbysoft History
                                </Link>
                            </span>

                            <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/derbysoft-receiver")}`}>
                                <Link to="/derbysoft-receiver"
                                      onClick={(e) => {
                                          if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                              if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                  setSidebarVisible(false)
                                                  setAskBeforeLeavingCalendar(false);
                                              } else {
                                                  e.preventDefault();
                                              }
                                      }}
                                      className="flex flex-row"
                                >
                                    <i className="fa-solid fa-hotel fa-fw mr-2"/>
                                    Derbysoft Receiver
                                </Link>
                            </span>

                            <span className={`text-lg ml-5 px-2 py-1 outline-1 outline-[#4f959d] hover:outline hover:bg-[#4f959d] hover:bg-opacity-10 rounded-md ${isCurrentPath("/derbysoft-receiver-history")}`}>
                                <Link to="/derbysoft-receiver-history"
                                      onClick={(e) => {
                                          if(isCurrentPath("/cloudbeds-calendar") && askBeforeLeavingCalendar)
                                              if(window.confirm("You have work in progress, are you sure you want to leave?")) {
                                                  setSidebarVisible(false)
                                                  setAskBeforeLeavingCalendar(false);
                                              } else {
                                                  e.preventDefault();
                                              }
                                      }}
                                      className="flex flex-row"
                                >
                                    <i className="fa-solid fa-hotel fa-fw mr-2"/>
                                    Derbysoft Receiver History
                                </Link>
                            </span>
                        </motion.div>
                    </motion.section>}
                </AnimatePresence>
            </>}
        </div>

    </Sidebar>
}