import React from "react";
import {useFormState} from "../../../data/Form";
import {formHasErrors} from "../../../lib/helpers";
import {postJsonToBackend} from "../../../data/network";

const initialState = {id: '', fullName: '', password: '', username: '', info: ''};
export default function AddUserComponent({afterAdd, hideComponent}:{afterAdd: () => void, hideComponent: () => void}) {

    const {inputValues, handleInputChange, resetInputValues, dirty} = useFormState(initialState)
    const erori: { [x: string]: string[] } = {fullName: [], password: [], username: []};

    async function addUser(e: any) {
        e.preventDefault();
        await postJsonToBackend("/users/add", {username: inputValues.username, password: inputValues.password, fullName: inputValues.fullName, info: inputValues.info});
        resetInputValues(initialState);
        afterAdd();
    }

    if (!inputValues.fullName && dirty) {
        erori.fullName.push("Full name is mandatory");
    }

    if (!inputValues.password && dirty) {
        erori.password.push("Password is mandatory");
    }

    if (!inputValues.username && dirty) {
        erori.username.push("Username is mandatory");
    }


    return <>
        <div className="shadow-md bg-white/70 rounded-lg flex flex-col">
            <div className="px-6 py-4 bg-white/50 flex rounded-t-lg font-bold text-md">
                <div>New User</div>
                <div className="ml-auto">
                    <i
                        className="fa-regular fa-circle-xmark fa-lg text-red-900 hover:text-red-700 cursor-pointer"
                        onClick={(e) => hideComponent()}
                    />
                </div>
            </div>

            <div className="flex flex-col py-4 px-6">
                <form onSubmit={addUser}>
                    <div className="mb-2">
                        <label className={`block mb-2 font-medium ${erori.fullName.length > 0 ? "text-red-500" : ""}`} htmlFor="fullName">Full Name</label>
                        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.fullName.length > 0 ? " border-solid border-red-500" : " border-solid border-gray-200"}`} name="fullName" id="fullName" type="text" value={inputValues.fullName} onChange={handleInputChange}/>
                        {erori.fullName.length > 0 && <div className="text-red-500 text-sm">{erori.fullName[erori.fullName.length-1]}</div>}
                    </div>

                    <div className="mb-2">
                        <label className={`block mb-2 font-medium ${erori.username.length > 0 ? "text-red-500" : ""}`} htmlFor="username">Username</label>
                        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.username.length > 0 ? " border-solid border-red-500" : " border-solid border-gray-200"}`} name="username" id="username" type="text" value={inputValues.username} onChange={handleInputChange}/>
                        {erori.username.length > 0 && <div className="text-red-500 text-sm">{erori.username[erori.username.length-1]}</div>}
                    </div>

                    <div className="mb-2">
                        <label className={`block mb-2 font-medium ${erori.password.length > 0 ? "text-red-500" : ""}`} htmlFor="password">Password</label>
                        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.password.length > 0 ? " border-solid border-red-500" : " border-solid border-gray-200 "}`} name="password" id="password" type="text" value={inputValues.password} onChange={handleInputChange}/>
                        {erori.password.length > 0 && <div className="text-red-500 text-sm">{erori.password[erori.password.length-1]}</div>}
                    </div>

                    <div className="mb-2">
                        <label className={`block mb-2 font-medium`} htmlFor="info">Info</label>
                        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white border-solid border-gray-200`} name="info" id="info" type="text" value={inputValues.info} onChange={handleInputChange}/>
                    </div>

                    <div className="w-full text-center">
                        {!formHasErrors(erori) && dirty && <button
                            type="submit"
                            className="border p-1 px-2 rounded bg-[#576f75] hover:bg-[#485e63] text-white"
                        >
                            Add
                        </button>}
                    </div>
                </form>
            </div>
        </div>
    </>
}