import {PickList, PickListChangeEvent} from "primereact/picklist";
import {Dialog} from "primereact/dialog";
import {UserRole, UserRoleSelectedOrAvailable} from "../../../data/BackendClasses";
import {useContext, useEffect, useState} from "react";
import {getJsonFromBackend, postJsonToBackend} from "../../../data/network";
import {GlobalContext} from "../../../data/GlobalContext";
import {ProgressSpinner} from "primereact/progressspinner";


export default function ManagementUserRoles(
    {
        showUserRolesPickList,
        setShowUserRolesPickList,
        selectedUserId,
        onClose
    }:{
        showUserRolesPickList: boolean,
        setShowUserRolesPickList: (show: boolean) => void,
        selectedUserId: number,
        onClose: () => void
    }
) {

    const [availableRoles, setAvailableRoles] = useState<UserRole[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<UserRole[]>([]);
    const [rolesChanged, setRolesChanged] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);


    const {showToastMessage} = useContext(GlobalContext);


    useEffect(() => {
        setLoading(prevState => true);
        getJsonFromBackend("/roles/available-and-selected?userId=" + selectedUserId)
            .then((rsp: UserRoleSelectedOrAvailable) => {
                setLoading(prevState => false);
                setSelectedRoles(rsp.selected);
                setAvailableRoles(rsp.available);
            })
            .catch(err => {
                setLoading(prevState => false);
                console.log("ERR getting user roles", err);
                showToastMessage("Error", "Error getting user roles", "error");
            })
    }, [])

    function onChange(event: PickListChangeEvent) {
        setRolesChanged(true);
        setAvailableRoles(event.source);
        setSelectedRoles(event.target);
    };

    async function saveRoles() {
        setLoading(prevState => true);
        postJsonToBackend("/users/update-roles", {userId: selectedUserId, newRoles: selectedRoles})
            .then(rsp => {
                setLoading(prevState => false);
                showToastMessage("Success", "Roles granted successfully", "success");
                onClose();
            })
            .catch(err => {
                setLoading(prevState => false);
                showToastMessage("Error", "Error setting user roles", "error");
                console.log(err);
            });
    }

    const itemTemplate = (item: UserRole) => {
        return (
            <div className="flex flex-wrap p-2 align-items-center gap-3">
                <div className="flex-1 flex flex-column gap-2">
                    <span className="font-bold">{item.role}</span>
                </div>
            </div>
        );
    };

    return <div id="management-user-roles">
        <Dialog
            header="Choose roles"
            visible={showUserRolesPickList}
            style={{ width: '50vw', minWidth: "fit-content" }}
            onHide={() => setShowUserRolesPickList(false)}
        >
            <div className="flex flex-col w-full">
                {loading ?
                    <div className="p-5 w-full text-center">
                        <ProgressSpinner/>
                    </div>
                :
                    <>
                        <PickList
                            source={availableRoles}
                            target={selectedRoles}
                            onChange={onChange}
                            itemTemplate={itemTemplate}
                            filter
                            filterBy="name,address"
                            breakpoint="1400px"
                            sourceHeader="Available"
                            targetHeader="Selected"
                            sourceStyle={{ height: '24rem' }}
                            targetStyle={{ height: '24rem' }}
                            sourceFilterPlaceholder="Search by name or address"
                            targetFilterPlaceholder="Search by name or address"
                            showSourceControls={false}
                            showTargetControls={false}
                            pt={{
                                moveToTargetButton: {root: {className: "!bg-[#576f75] hover:!bg-[#485e63] !border-none"}},
                                moveToSourceButton: {root: {className: "!bg-[#576f75] hover:!bg-[#485e63] !border-none"}},
                                moveAllToTargetButton: {root: {className: "!bg-[#576f75] hover:!bg-[#485e63] !border-none"}},
                                moveAllToSourceButton: {root: {className: "!bg-[#576f75] hover:!bg-[#485e63] !border-none"}},
                            }}
                        />
                        <div className="w-full mt-2 flex justify-center">
                            {rolesChanged && <button
                                onClick={saveRoles}
                                className="border p-1 px-2 rounded bg-[#576f75] hover:bg-[#485e63] text-white"
                            >
                                Done
                            </button>}
                        </div>
                    </>
                }
            </div>
        </Dialog>
    </div>

}