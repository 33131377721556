import {Dialog} from "primereact/dialog";
import React, {ChangeEvent, useState} from "react";
import {postJsonToBackend} from "../../../data/network";

export default function ManagementUserPassword(
    {
        showUserPasswordChange,
        setShowUserPasswordChange,
        selectedUserId,
        onClose
    }:{
        showUserPasswordChange: boolean,
        setShowUserPasswordChange: (show: boolean) => void,
        selectedUserId: number,
        onClose: () => void
    }
) {

    const [newPassword, setNewPassword] = useState<string | undefined>(undefined);

    async function changePassword() {
        await postJsonToBackend("/users/change-password", {userId: selectedUserId, newPassword: newPassword});
        onClose();
    }

    return <div id="management-user-password">
        <Dialog
            header="Change Password"
            visible={showUserPasswordChange}
            style={{ width: '50vw' }}
            onHide={() => setShowUserPasswordChange(false)}
        >

            <div className="flex flex-col">
                <div className="w-full">
                    <label htmlFor="password" className="font-medium">New Password</label>
                    <input className="custom-form w-full border border-solid rounded-lg block 2.5" name="password" id="password" type="text" value={newPassword} onChange={e => setNewPassword(e.target.value)}/>
                </div>

                <div className="w-full flex justify-center">
                    {newPassword && <button
                        className="border p-1 px-2 rounded bg-[#576f75] hover:bg-[#485e63] text-white mt-2"
                        onClick={changePassword}
                    >
                        Change
                    </button>}
                </div>

            </div>




        </Dialog>
    </div>

}