import {Column} from "primereact/column";
import React, {useContext, useEffect, useState} from "react";
import {DataTable, DataTableFilterMeta} from "primereact/datatable";
import {BankingSecurityCode} from "../../data/BackendClasses";
import {FilterMatchMode} from "primereact/api";
import {classNames} from "primereact/utils";
import {getJsonFromBackend} from "../../data/network";
import {GlobalContext} from "../../data/GlobalContext";
import {formatDate} from "../../lib/helpers";
import {InputText} from "primereact/inputtext";


export default function BankingSecurityCodePage() {

    const [data, setData] = useState<BankingSecurityCode[]>([]);
    const [reload, setReload] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [first, setFirst] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [filters, setFilters] = useState<DataTableFilterMeta>({
        global: {
            value: null,
            matchMode: FilterMatchMode.CONTAINS
        }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');

    const {showToastMessage} = useContext(GlobalContext);

    const rowClass = (data: any) => {
        return {'!bg-transparent': true};
    };

    useEffect(() => {
        getJsonFromBackend("/banking-security-code/get")
            .then((rsp: BankingSecurityCode[]) => {
                setData(prevState => rsp);
                setLoading(prevState => false);
            })
            .catch(err => {
                showToastMessage("ERROR", "Error getting data", "error");
                console.log(err)
                setLoading(false);
            })
    }, [reload])


    function renderCreatedAt(item: BankingSecurityCode){
        return formatDate(item.createdAt);
    }

    function renderHeader() {
        return <div className="flex flex-row justify-content-end">
                <span className="p-input-icon-left">
                    <i className="fa fa-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Filter" />
                </span>
        </div>
        // return <div className="text-xl">Banking Security Codes</div>
    }

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };


    return <>
        <div className="flex gap-1 mx-auto pt-8 pb-4 px-4 w-full h-full" id="banking-security-code-page">

            <div className="flex flex-col h-fit !rounded-lg bg-white/70 p-1 grow">

                <DataTable
                    stripedRows={true}
                    value={data}
                    filters={filters}
                    paginator
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} -> {last} of {totalRecords}"
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    first={first}
                    rows={pageSize}
                    totalRecords={data.length}
                    tableStyle={{minWidth: '50rem'}}
                    rowClassName={rowClass}
                    className={classNames('w-full !rounded-md', {})}
                    emptyMessage="No data found."
                    loading={loading}
                    header= {renderHeader()}
                    pt={{
                        header: {className: "!bg-white/70 !border-0 !rounded-t-md focus:!shadow-none"},
                        paginator: {
                            root: {className: "!bg-white/70 !text-[#644243] !border-0"},
                            current: {className: "!text-[#4f959d] !border-0"},
                            nextPageButton: {className: "!text-[#4f959d] focus:!shadow-none !border-0"},
                            lastPageButton: {className: "!text-[#4f959d] focus:!shadow-none !border-0"},
                            prevPageButton: {className: "!text-[#4f959d] focus:!shadow-none !border-0"},
                            firstPageButton: {className: "!text-[#4f959d] focus:!shadow-none !border-0"},
                            RPPDropdown: {
                                root: {className: "focus:!shadow-none !border-0"},
                                input: {className: "focus:!shadow-none !text-[#4f959d]"},
                                select: {className: "focus:!shadow-none !text-[#4f959d]"},
                                trigger: {className: "focus:!shadow-none !text-[#4f959d]"},
                            }
                        }
                    }}
                >

                    <Column className="!bg-white " field="id" header="ID" pt={{headerTitle: {className: "!font-bold"}}}/>
                    <Column className="!bg-white " field="bookingId" header="Booking ID" pt={{headerTitle: {className: "!font-bold"}}}/>
                    <Column className="!bg-white " field="securityCode" header="Security Code" pt={{headerTitle: {className: "!font-bold"}}}/>
                    <Column className="!bg-white " field="createdAt" header="Created At" pt={{headerTitle: {className: "!font-bold"}}} body={renderCreatedAt}/>
                    <Column className="!bg-white " field="propertyId" header="Property Id" pt={{headerTitle: {className: "!font-bold"}}}/>
                    <Column className="!bg-white " field="firstName" header="First Name" pt={{headerTitle: {className: "!font-bold"}}}/>
                    <Column className="!bg-white " field="lastName" header="Last Name" pt={{headerTitle: {className: "!font-bold"}}}/>
                    <Column className="!bg-white " field="reference" header="Reference" pt={{headerTitle: {className: "!font-bold"}}}/>
                </DataTable>

            </div>
        </div>

    </>

}