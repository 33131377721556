import {Dialog} from "primereact/dialog";
import {Booking} from "../../../data/BackendClasses";
import {useState} from "react";
import {formatDateWithoutTime} from "../../../lib/helpers";


export default function BookingDetailsDialog({showDialog, booking, onHide}:{showDialog: boolean, booking: Booking, onHide: () => void}) {

    const [showPhone, setShowPhone] = useState<boolean>(false);

    function daysBetween(startDate: string, endDate: string): number {
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
        const differenceInMilliseconds = endDateObj.getTime() - startDateObj.getTime();
        // One day in milliseconds
        const oneDay = 1000 * 60 * 60 * 24;
        return Math.ceil(differenceInMilliseconds / oneDay);
    }

    return <>
        <Dialog
            header={
                <div className="flex flex-row">
                    <div className="flex flex-col text-xl">
                        <div>
                            Reservation id:
                            <span className="font-bold ml-2">{booking.id}</span>
                        </div>
                        <div>
                            Room:
                            <span className="font-bold ml-2">{booking.roomId}</span>
                        </div>
                    </div>
                    <div className="ml-auto mr-5 px-1 rounded-full px-3 py-2 text-sm bg-green-300 content-center h-fit my-auto">
                        Booked:  {formatDateWithoutTime(booking.bookingTime)}
                    </div>

                </div>

            }
            style={{ minWidth: '40vw', minHeight: 'fit-content', width: '40vw', height: 'fit-content'}}
            visible={showDialog}
            onHide={onHide}
        >

            <div className="flex flex-row h-full">
                <div className="flex flex-col w-1/3 h-full gap-2 pl-2">
                    <div className="flex flex-col">
                        <div className="text-black text-base">Status</div>
                        <div className="text-sm font-semibold text-[#778295]">{booking.status.toUpperCase()}</div>
                    </div>
                    <div className="flex flex-col">
                        <div className="text-black text-base">Check in</div>
                        <div className="text-sm font-semibold text-[#778295]">{booking.arrival}</div>
                    </div>
                    <div className="flex flex-col">
                        <div className="text-black text-base">Check out</div>
                        <div className="text-sm font-semibold text-[#778295]">{booking.departure}</div>
                    </div>
                    <div className="flex flex-col">
                        <div className="text-black text-base">Length of stay</div>
                        <div className="text-sm font-semibold text-[#778295]">{daysBetween(booking.arrival, booking.departure)} nights</div>
                    </div>
                    <div className="flex flex-col">
                        <div className="text-black text-base">Total guests</div>
                        <div className="text-sm font-semibold text-[#778295]">{`${booking.numAdult} adults ${booking.numChild > 0 ? `, ${booking.numChild} ${booking.numChild > 1 ? "children" : "child"}` : ""}`}</div>
                    </div>
                    {/*<div className="flex flex-col">*/}
                    {/*    <div className="text-slate-500">Total rooms</div>*/}
                    {/*    <div className="font-semibold">{booking.roomQty}</div>*/}
                    {/*</div>*/}
                    {/*<div className="flex flex-col">*/}
                    {/*    <div className="text-slate-500">Total price</div>*/}
                    {/*    <div className="text-2xl font-semibold">US${Number(booking.price + booking.commission + booking.tax).toFixed(2)}</div>*/}
                    {/*</div>*/}

                </div>
                <div className="flex flex-col w-2/3 h-full gap-2 pr-2">
                    <div className="flex flex-col">
                        <div className="text-black text-base">Guest name:</div>
                        <div className="text-sm font-bold text-[#778295]">{booking.firstName} {booking.lastName}</div>
                    </div>

                    <div className="flex flex-col">
                        <div className="text-black text-base">Channel:</div>
                        <div className="text-sm font-bold text-[#778295]">{booking.referer}</div>
                    </div>
                    <div className="flex flex-col">
                        <div className="text-black text-base">Airbnb ID:</div>
                        <div className="text-sm font-bold text-[#778295]">{booking.apiReference}</div>
                    </div>

                    <div className="flex flex-row">
                        <div className="flex flex-col w-1/2">
                            <div className="text-black text-base">Booking number:</div>
                            <div className="text-sm font-bold text-[#778295]">{booking.id}</div>
                        </div>
                    </div>

                    {booking.email && <div className="flex flex-row mb-1 text-base">
                        Email:
                        <a className="text-[#778295] hover:underline ml-2" href={`mailto:${booking.email}`}>{booking.email}</a>
                    </div>}

                    <div className="flex flex-col">
                        <div className="text-[#778295] font-bold text-base">
                            {showPhone
                                ? <><i className="fa-solid fa-phone mr-1"/> <a href={`tel:${booking.phone}`}>{booking.phone}</a></>
                                : <div className="hover:cursor-pointer hover:text-gray-500 text-base" onClick={() => setShowPhone(true)}>
                                    <i className="fa-regular fa-eye" /> Show phone number
                                </div>
                            }
                        </div>
                    </div>

                </div>
            </div>

        </Dialog>
    </>

}