import {Calendar, CalendarChangeEvent} from "primereact/calendar";
import React, {useContext, useEffect, useState} from "react";
import {Nullable} from "primereact/ts-helpers";
import "./CloudbedsReports.scss";
import {MultiSelect} from "primereact/multiselect";
import {Chips, ChipsAddEvent, ChipsRemoveEvent} from "primereact/chips";
import {Tooltip} from "primereact/tooltip";
import {CloudbedsHotel, Role} from "../../../data/BackendClasses";
import {GlobalContext} from "../../../data/GlobalContext";
import {getJsonFromBackend, postJsonToBackend} from "../../../data/network";
import {formatDateWithoutTime, formHasErrors} from "../../../lib/helpers";
import {Skeleton} from "primereact/skeleton";
import {isReturnedErrors} from "../../../components/ErrorModal";
import {Link} from "react-router-dom";
import {useCurrentUser} from "../../../data/user";

interface FileType {
    name: string;
    value: number
}

export default function CloudbedsReports() {

    const fileTypes : FileType[] = [{name: 'Arrivals', value: 1}, {name: 'Daily Financial Summary', value: 2}, {name: 'Daily Financial Summary per roomtype', value: 3}]
    const bookingStatuses : string[] = ["Confirmed", "Canceled"];

    const [startDate, setStartDate] = useState<string | Date | Date[] | undefined | null>(undefined);
    const [endDate, setEndDate] = useState<Nullable<Date | Date[] | string>>(null);
    const [availableHotels, setAvailableHotels] = useState<CloudbedsHotel[]>([]);
    const [selectedHotels, setSelectedHotels] = useState<CloudbedsHotel[]>([]);
    const [hotelsChanged, setHotelsChanged] = useState<boolean>(false);
    const [hotelsLoaded, setHotelsLoaded] = useState<boolean>(false);
    const [hotelsLoading, setHotelsLoading] = useState<boolean>(true);

    const [selectedFileTypes, setSelectedFileTypes] = useState<FileType[]>([]);
    const [selectedBookingStatuses, setSelectedBookingStatuses] = useState<string[]>([]);


    const [emails, setEmails] = useState<string[] | undefined>([]);
    const [dirty, setDirty] = useState<boolean>(false);
    const erori: { [x: string]: string[] } = {hotels: [], fileTypes: [], startMonth: [], endMonth: [], emails: [], bookingStatus: []};

    const {showToastMessage} = useContext(GlobalContext);

    const baseUrl = process.env.PUBLIC_URL + "";

    const currentUser = useCurrentUser();

    useEffect(() => {
        getJsonFromBackend("/cloudbeds-calendar/hotels/get-my-hotels-with-property-id")
            .then((rsp: CloudbedsHotel[]) => {
                setAvailableHotels(rsp);
                setHotelsLoaded(true);
                setHotelsLoading(prevState => false);
            })
            .catch(err => {
                showToastMessage("Error", "Error getting the data about the hotels", "error");
                setHotelsLoading(prevState => false);
                console.log(err);
            })
    }, [])

    function changeStartDate(e: CalendarChangeEvent) {
        setStartDate(e.value);
        setDirty(true);
    }

    function changeEndDate(e: CalendarChangeEvent) {
        setEndDate(e.value);
        setDirty(true);
    }

    function emailRemoved(e: ChipsRemoveEvent){
        setEmails(prevState => {
            if(undefined !== prevState) {
                return prevState.filter((item) => item != e.value);
            }
        })
        setDirty(true);
    }

    function emailAdded(e: ChipsAddEvent) {
        if(isValidEmail(e.value)){
            setEmails(undefined !== emails ? [...emails, e.value] : [e.value])
        } else {
            erori.emails.push("Email is not valid");
            showToastMessage("Warning", "Provided email is not a valid email value", "warn");
        }
        setDirty(true);
    }

    if(selectedHotels.length == 0){
        erori.hotels.push("You should select at least 1 hotel")
    }

    if(selectedBookingStatuses.length == 0){
        erori.hotels.push("You should select at least 1 booking status")
    }

    if(selectedFileTypes.length == 0){
        erori.fileTypes.push("You should select at least 1 type")
    }

    if(emails == undefined || emails?.length == 0){
        erori.emails.push("You must provide at least 1 email")
    }

    if(startDate == null) {
        erori.startMonth.push("Start date is mandatory");
    }

    if(endDate == null) {
        erori.endMonth.push("End date is mandatory");
    }

    if(startDate != null && endDate != null && startDate > endDate){
        erori.startMonth.push("Start date can not be bigger than the end date")
        erori.endMonth.push("End date can not be lower than the start date")
    }


    function isValidEmail(email: string) : boolean {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }



    async function generate() {
        // @ts-ignore
        const requestBody = {hotels: selectedHotels, fromDate: formatDateWithoutTime(startDate), toDate: formatDateWithoutTime(endDate), emails: emails, fileTypes: selectedFileTypes, bookingStatus: selectedBookingStatuses};

        await postJsonToBackend("/cloudbeds-reports/generate", requestBody)
            .then(rsp => {
                showToastMessage("Success", "We have launched your report. It will be delivered to the email(s) within 30 minutes. In the meantime, we suggest having coffee, walking, or calling a loved one :)", "success");
            }).catch(err => {
                if(isReturnedErrors(err)){
                    showToastMessage("Error", err.errors[0].message, "error");
                } else {
                    showToastMessage("Error", "Error sending the data", "error");
                }
                console.log(err);
            })
    }







    return <div id="cloudbeds-reports-page" className="flex flex-col items-center justify-center w-full gap-2 h-full relative">

        <div className="flex flex-row !w-full gap-3 absolute top-10 left-10">
            <span className="text-xl font-bold">Reports</span>

            {(currentUser.roles.includes(Role.ROCKETSTAY_RESERVATIONS) || currentUser.roles.includes(Role.ROCKETSTAY_MASTER)) && <>
                <span className="text-xl font-semibold">
                /
                </span>
                <Link to={"/cloudbeds-bookings"}>
                    <span className="text-xl hover:text-[#4f959d] font-semibold">Reservations</span>
                </Link>
            </>}

            {(currentUser.roles.includes(Role.ROCKETSTAY_CALENDAR) || currentUser.roles.includes(Role.ROCKETSTAY_MASTER)) && <>
                <span className="text-xl font-semibold">
                    /
                </span>

                <Link to={"/cloudbeds-calendar"}>
                    <span className="text-xl hover:text-[#4f959d] font-semibold">Calendar</span>
                </Link>
            </>}
        </div>

        <div className="container mb-20 w-full">
            <img src={baseUrl + "/name_logo.png"} className="w-1/3 m-auto"/>
        </div>

        <div className="w-full md:w-1/2 lg:w-1/2 p-6 bg-white backdrop-blur rounded-xl flex flex-col shadow-lg border border-gray-100">
            <div className="w-3/4 gap-2 flex content-center justify-center m-auto">
                {
                    hotelsLoading && <Skeleton className="!h-full w-1/2"/>
                }
                {
                    hotelsLoaded && <div className="flex flex-col w-1/2">
                        <label className="font-medium" htmlFor="hotels">Hotels</label>
                        <MultiSelect
                            name="hotels"
                            value={selectedHotels}
                            options={availableHotels}
                            onChange={e => {setDirty(true); setSelectedHotels(e.value)}}
                            optionLabel="name"
                            placeholder="Select hotels"
                            maxSelectedLabels={0}
                            className={`${erori.hotels.length > 0 && dirty ? "p-invalid" : ""}`}
                            pt={{
                                label: {className: erori.hotels.length > 0 && dirty ? "!text-red-500" : ""}
                            }}
                        />
                    </div>
                }

                <div className="flex flex-col w-1/2">
                    <label className="font-medium" htmlFor="file-types">Types</label>
                    <MultiSelect
                        name="file-types"
                        value={selectedFileTypes}
                        options={fileTypes}
                        onChange={e => {setDirty(true); setSelectedFileTypes(e.value)}}
                        optionLabel="name"
                        placeholder="Select types"
                        maxSelectedLabels={0}
                        className={`${erori.fileTypes.length > 0 && dirty ? "p-invalid" : ""}`}
                        pt={{
                            label: {className: erori.fileTypes.length > 0 && dirty ? "!text-red-500" : ""}
                        }}
                    />
                </div>

            </div>


            <div className="flex flex-row w-3/4 gap-2 mt-4 m-auto">
                <div className="flex flex-row w-2/3 gap-2">
                    <div className="w-1/2 flex flex-col">
                        <label className="font-medium" htmlFor="start-date">Start Date</label>
                        <Calendar
                            value={startDate}
                            onChange={(e) => changeStartDate(e)}
                            showIcon
                            showButtonBar
                            view="date"
                            dateFormat="yy-mm-dd"
                            // maxDate={new Date()}
                            className={`${erori.startMonth.length > 0 && dirty ? "p-invalid" : ""}`}
                            pt={{
                                day:{className: "!m-[1px] !p-[1px]"},
                                container: {className: "!p-[2px]"},
                                dropdownButton: {
                                    root: {className: "!bg-[#576f75] !border-[#576f75] focus:!shadow-none"}
                                }
                            }}
                        />
                        {erori.startMonth.length > 0 && dirty && <span className="text-red-500 font-sm">{erori.startMonth[erori.startMonth.length-1]}</span>}
                    </div>

                    <div className="w-1/2 flex flex-col">
                        <label className="font-medium" htmlFor="start-date">End Date</label>
                        <Calendar
                            value={endDate}
                            onChange={(e) => changeEndDate(e)}
                            showIcon
                            showButtonBar
                            view="date"
                            dateFormat="yy-mm-dd"
                            className={`${erori.endMonth.length > 0 && dirty ? "p-invalid" : ""}`}
                            pt={{
                                day:{className: "!m-[1px] !p-[1px]"},
                                container: {className: "!p-[2px]"},
                                dropdownButton: {
                                    // root: {className: "!bg-black !border-black focus:!shadow-none"}
                                    root: {className: "!bg-[#576f75] !border-[#576f75] focus:!shadow-none"},
                                    icon: {className: "!bg-white !text-white"}
                                }
                            }}
                        />
                        {erori.endMonth.length > 0 && dirty && <span className="text-red-500 font-sm">{erori.endMonth[erori.endMonth.length-1]}</span>}
                    </div>
                </div>

                <div className="flex flex-col">
                    <label className="font-medium" htmlFor="booking-status">Booking Status</label>
                    <MultiSelect
                        name="booking-status"
                        value={selectedBookingStatuses}
                        options={bookingStatuses}
                        onChange={e => {setDirty(true); setSelectedBookingStatuses(e.value)}}
                        // optionLabel="name"
                        placeholder="Select booking status"
                        maxSelectedLabels={0}
                        className={`${erori.bookingStatus.length > 0 && dirty ? "p-invalid" : ""}`}
                        pt={{
                            label: {className: erori.bookingStatus.length > 0 && dirty ? "!text-red-500" : ""}
                        }}
                    />
                </div>
            </div>

            <div className="w-full flex flex-col mt-4">
                <label className="font-medium w-3/4 m-auto" htmlFor="">Emails (after each email hit enter)</label>
                <div className="w-3/4 m-auto flex flex-row">
                    <Chips className={`w-full ${erori.emails.length > 0 && dirty ? "p-invalid" : ""}`}  name="emails" value={emails} onRemove={emailRemoved} onAdd={emailAdded}/>
                    <div className="flex flex-col justify-center ml-2">
                        <i className={`fa-regular fa-circle-question tooltip-emails-target cursor-pointer ${erori.emails.length > 0 && dirty ? "text-red-500" : "text-indigo-500"}`}/>
                        <Tooltip target=".tooltip-emails-target" >
                            <span>After each email hit enter</span>
                        </Tooltip>
                    </div>
                </div>
                {erori.emails.length > 0 && dirty && <span className="text-red-500 font-sm m-auto">{erori.emails[erori.emails.length-1]}</span>}
            </div>

            <div className="w-full text-center">
                {/*{isFormValid() && dirty && <button*/}
                {!formHasErrors(erori) && dirty && <button
                    className="border border-transparent font-medium rounded-md text-white bg-[#576f75] hover:bg-[#485e63] p-2 mt-5"
                    onClick={generate}
                >
                    Submit
                </button>}
            </div>
        </div>

        <div className="mt-10">Need help? Contact us at <a href="mailto:admin@rocketstay.com" className="text-blue-500">admin@rocketstay.com</a></div>

        {/*<InfoDialog*/}
        {/*    header={"Info"}*/}
        {/*    message={*/}
        {/*        <div>*/}
        {/*            <p>The November financial reports are currently unavailable due to scheduled maintenance.</p>*/}
        {/*            <p>They will be accessible starting <b>November 25th</b>.</p>*/}
        {/*            <p>We sincerely apologize for any inconvenience</p>*/}
        {/*        </div>*/}
        {/*    }*/}
        {/*/>*/}

    </div>



}