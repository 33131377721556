import {AuthenticatedUser} from "./BackendClasses";
import {createContext, ReactNode, RefObject, useEffect, useRef, useState} from "react";
import {Toast, ToastMessage} from "primereact/toast";
import {MenuItem} from "primereact/menuitem";

interface ContextType {
    user: AuthenticatedUser | undefined,
    setUser: (newUser: AuthenticatedUser) => void,
    toastRef: RefObject<Toast>,
    showToastMessage: (title: string, description: string, severity: "success" | "info" | "warn" | "error" | undefined) => void,
    sseLoginEvent: EventSource | undefined,
    sidebarVisible: boolean,
    setSidebarVisible: (visible: boolean) => void,
    askBeforeLeavingCalendar: boolean,
    setAskBeforeLeavingCalendar: (ask: boolean) => void
}

const GlobalContext = createContext({} as ContextType);
const backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

const GlobalContextProvider = ({children}: {children: ReactNode}) => {
    const [user, setUser1] = useState<AuthenticatedUser | undefined>(undefined);
    const [sseLoginEvent, setSseLoginEvent] = useState<EventSource | undefined>(undefined)
    const [sidebarVisible, setSidebarVisible1] = useState<boolean>(true);
    const [askBeforeLeavingCalendar, setAskBeforeLeavingCalendar1] = useState<boolean>(false);

    useEffect(() => {
        const newSseLoginEvent: EventSource = new EventSource(
            `${backendBaseUrl}/chat/sse-server/login-subscribe`,
            {withCredentials: true,}
        )

        if(undefined == sseLoginEvent){
            console.log("SET SSE LOGIN EVENT")
            setSseLoginEvent(newSseLoginEvent);
        }

    }, [])

    const setUser = (newUser: AuthenticatedUser) => {
        setUser1(newUser);
    }

    const setSidebarVisible = (visible: boolean) => {
        setSidebarVisible1(visible);
    }

    const setAskBeforeLeavingCalendar = (ask: boolean) => {
        setAskBeforeLeavingCalendar1(ask);
    }

    const toastRef = useRef<Toast>(null);
    const showToastMessage = (title: string, description:string, severity: ToastMessage['severity']) => {
        toastRef.current?.show({severity: severity, summary: title, detail: description, life: 10000});
    }

    const contextValues = {
        user,
        setUser,
        toastRef,
        showToastMessage,
        sseLoginEvent,
        sidebarVisible,
        setSidebarVisible,
        askBeforeLeavingCalendar,
        setAskBeforeLeavingCalendar
    };

    return (
        <GlobalContext.Provider value={contextValues}>
            {children}
        </GlobalContext.Provider>
    );
};

export {GlobalContext, GlobalContextProvider}