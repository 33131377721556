import {Dialog} from "primereact/dialog";
import {Calendar, CalendarChangeEvent} from "primereact/calendar";
import React, {useEffect, useState} from "react";
import {RoomData} from "./CloudbedsCalendarPage";
import {formHasErrors, newDateFromString} from "../../../lib/helpers";
import {Room} from "./BulkUpdateDialog";
import {MultiSelect, MultiSelectChangeEvent} from "primereact/multiselect";


export default function ChangeMultiplierDialog(
        {
            showDialog,
            hideDialog,
            startDate,
            room,
            multiplierValue,
            onApply,
            rooms
        } : {
            showDialog: boolean,
            hideDialog: () => void,
            startDate: string | Date | Date[] | undefined | null,
            room: RoomData,
            multiplierValue:number,
            onApply: (roomIds: number[], dates: string[], newMultiplier: number) => void,
            rooms: Room[]
        }
    ) {

    const [internalStartDate, setInternalStartDate] = useState<string | Date | Date[] | undefined | null>(undefined);
    const [internalEndDate, setInternalEndDate] = useState<string | Date | Date[] | undefined | null>(undefined);
    const [internalMultiplierValue, setInternalMultiplierValue] = useState<string>(multiplierValue.toString());
    const erori: { [x: string]: string[] } = {multiplier: []};

    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const [selectedDays, setSelectedDays] = useState<boolean[]>(Array(7).fill(true));

    const [selectedRooms, setSelectedRooms] = useState<Room[]>([{roomId: room.roomId, name: room.name, linked: room.linked}]);


    if(internalMultiplierValue == ''){
        erori.multiplier.push("The new value can't be empty");
    } else if(isNaN(+internalMultiplierValue)){
        erori.multiplier.push("The new value is not a valid number");
    } else if(parseFloat(internalMultiplierValue) < 10 || parseFloat(internalMultiplierValue) > 1000){
        erori.multiplier.push("The new value must be between 10 and 1000");
    }


    function changeEndDate(e: CalendarChangeEvent) {
        setInternalEndDate(e.value);
    }

    const handleMultiplierChange = (event: any) => {
        setInternalMultiplierValue(prevState => event.target.value);
    };

    function isDate(obj: any): obj is Date {
        return obj instanceof Date;
    }

    function handleDayChange(newState: boolean, index: number) {
        setSelectedDays(prevState => [
                ...selectedDays.slice(0, index),
                newState,
                ...selectedDays.slice(index + 1)
            ]
        )
    }

    function applyChanges() {
        if(isDate(internalStartDate) && isDate(internalEndDate)){
            const dates = [];
            let currentDate = new Date(internalStartDate.getTime());

            while (currentDate <= internalEndDate) {
                const year = currentDate.getFullYear();
                const month = String(currentDate.getMonth() + 1).padStart(2, '0');
                const day = String(currentDate.getDate()).padStart(2, '0');

                if(selectedDays[currentDate.getDay()]){
                    dates.push(`${year}-${month}-${day}`);
                }
                currentDate.setDate(currentDate.getDate() + 1);
            }

            onApply(selectedRooms.map(room => room.roomId), dates, parseFloat(internalMultiplierValue));
            hideDialog();
        }
    }

    useEffect(() => {
        setInternalStartDate(prevState => typeof startDate === 'string' ? newDateFromString(startDate) : startDate);
        setInternalEndDate(prevState => typeof startDate === 'string' ? newDateFromString(startDate) : startDate);
    }, [])

    return <>
        <Dialog
            header={`Change Multiplier`}
            visible={showDialog}
            pt={{
                root: {className: "w-full md:w-fit"}
            }}
            onHide={hideDialog}
            style={{ minWidth: 'fit-content', minHeight: 'fit-content'}}
        >
            <div className="flex flex-col w-full gap-3">

                <div className="flex flex-col w-1/3">
                    <label className="font-medium text-black" htmlFor="internalMultiplierValue">Multiplier (%)</label>
                    <input
                        name="internalMultiplierValue"
                        type="text"
                        className="custom-form"
                        value={internalMultiplierValue}
                        onChange={handleMultiplierChange}
                    />
                    {erori.multiplier.length > 0 && <span className="text-red-500 font-sm">{erori.multiplier[erori.multiplier.length-1]}</span>}
                </div>

                <div className="flex flex-row w-full mt-2">
                    <div key="div-before" className="w-full flex mr-1">
                        <hr key="hr-before" className="w-full bg-gray-300 my-auto"/>
                    </div>
                    <div key="selected-div" className="w-fit content-center h-full text-center text-sm italic text-gray-500 whitespace-nowrap">
                        Select date intervals
                    </div>
                    <div key="div-after" className="w-full flex ml-1">
                        <hr key="hr-after" className="w-full bg-gray-300 my-auto"/>
                    </div>
                </div>

                <div className="flex flex-row w-full gap-2">
                    <div className="flex flex-col w-full">
                        <label className="font-medium text-black" htmlFor="start-date">Start Date</label>
                        <Calendar
                            id="calendar-24h"
                            value={internalStartDate}
                            // onChange={(e) => changeStartDate(e)}
                            showIcon
                            showButtonBar
                            view="date"
                            minDate={new Date()}
                            disabled={true}
                            pt={{
                                day:{className: "!m-[1px] !p-[1px]"},
                                container: {className: "!p-[2px]"},
                                dropdownButton: {
                                    root: {className: "!bg-[#576f75] !border-[#576f75] focus:!shadow-none"}
                                }
                            }}
                        />
                    </div>

                    <div className="flex flex-col w-full">
                        <label className="font-medium text-black" htmlFor="end-date">End Date</label>
                        <Calendar
                            id="calendar-24h"
                            value={internalEndDate}
                            onChange={(e) => changeEndDate(e)}
                            showIcon
                            showButtonBar
                            minDate={new Date()}
                            view="date"
                            pt={{
                                day:{className: "!m-[1px] !p-[1px]"},
                                container: {className: "!p-[2px]"},
                                dropdownButton: {
                                    root: {className: "!bg-[#576f75] !border-[#576f75] focus:!shadow-none"}
                                }
                            }}
                        />
                    </div>
                </div>

                <div className="flex flex-col md:flex-row w-full gap-4">
                    <div className="flex flex-row w-full mt-5 gap-3 justify-center">
                        {daysOfWeek.map((day, index) => (
                            <button
                                key={day}
                                value={day}
                                className={`${selectedDays[index] ? "text-white" : "text-black"} py-2 px-3 ${selectedDays[index] ? "bg-[#576f75] hover:bg-[#485e63] hover:text-white" : "bg-white"} border border-[#576f75] rounded hover:border-[#485e63] mt-auto text-xs sm:text-base`}
                                onClick={() => handleDayChange(!selectedDays[index], index)}
                            >
                                {day}
                            </button>
                        ))}
                    </div>
                </div>

                <div className="flex flex-row w-full mt-2">
                    <div key="div-before" className="w-full flex mr-1">
                        <hr key="hr-before" className="w-full bg-gray-300 my-auto"/>
                    </div>
                    <div key="selected-div" className="w-fit content-center h-full text-center text-sm italic text-gray-500 whitespace-nowrap">
                        Selected rooms
                    </div>
                    <div key="div-after" className="w-full flex ml-1">
                        <hr key="hr-after" className="w-full bg-gray-300 my-auto"/>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row w-full justify-center">
                    <div className="mt-2 max-w-[400px]">
                        <MultiSelect
                            name="apply-to"
                            value={selectedRooms}
                            onChange={(e: MultiSelectChangeEvent) => {
                                setSelectedRooms(e.value)}
                            }
                            options={rooms}
                            optionLabel="name"
                            placeholder="Select Rooms"
                            maxSelectedLabels={3}
                            className="w-full"
                        />
                    </div>
                </div>

                <div className="flex flex-row justify-center pt-5 gap-3">
                    { !formHasErrors(erori) && (selectedRooms.length > 0) && (selectedRooms.length > 0) && <div>
                        <button
                            className="border p-1 px-2 rounded bg-[#576f75] hover:bg-[#485e63] text-white"
                            onClick={applyChanges}
                        >
                            Save Draft
                        </button>
                    </div>}
                    <div>
                        <button
                            className="border p-1 px-2 rounded bg-[#576f75] hover:bg-[#485e63] text-white"
                            onClick={hideDialog}
                        >
                            Cancel
                        </button>
                    </div>
                </div>

            </div>
        </Dialog>
    </>

}