import {Dialog} from "primereact/dialog";
import {AppUserWithoutHotels} from "../../../data/BackendClasses";
import {useState} from "react";
import {postJsonToBackend} from "../../../data/network";
import {Skeleton} from "primereact/skeleton";


export default function UserInfoDialog({showUserInfoDialog, onHide, selectedUser, afterUpdate}:{showUserInfoDialog: boolean, onHide: () => void, selectedUser: AppUserWithoutHotels, afterUpdate: (newInfo: string) => void}) {

    const [innerInfo, setInnerInfo] = useState<string>(selectedUser.info);
    const [loading, setLoading] = useState<boolean>(false);

    function updateInfo(){
        setLoading(prevState => true);
        console.log("UPDATE USER INFO ", {...selectedUser, info: innerInfo})
        postJsonToBackend("/users/update-info", {...selectedUser, info: innerInfo})
            .then(rsp => {
                afterUpdate(innerInfo);
                setLoading(prevState => false);
                console.log("USER INFO UPDATED SUCCESSFULLY")
            })
            .catch(err => {
                setLoading(prevState => false);
                console.log("ERR UPDATING USER INFO", err)
            })
    }

    return <Dialog
        header={`${selectedUser.fullName} info`}
        visible={showUserInfoDialog}
        style={{ width: '50vw' }}
        onHide={onHide}
    >
        <div className="flex flex-col gap-3 ">
            {loading ?
                <Skeleton className="!h-full">
                    <textarea className="w-full rounded p-1 border border-solid border-gray-300 focus-visible:border-gray-400 focus-visible:outline-none invisible"  value={innerInfo} disabled/>
                </Skeleton>
            :
                <>
                    <textarea className="w-full rounded p-1 border border-solid border-gray-300 focus-visible:border-gray-400 focus-visible:outline-none"  value={innerInfo} onChange={e => setInnerInfo(e.target.value)}/>

                    {(innerInfo != selectedUser.info) && <button
                        className="border p-1 px-2 rounded bg-[#576f75] hover:bg-[#485e63] text-white w-fit m-auto"
                        onClick={updateInfo}
                    >
                        Save
                    </button>}
                </>
            }
        </div>




    </Dialog>

}