import {useEffect} from "react";


export default function BeforeUnloadComponent(message: string, show: boolean)  : void {

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if(show){
                event.preventDefault();
            }
        }

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        }

    }, [message, show])
}

