import dayjs from "dayjs";

export const isDev = () : boolean => !process.env.NODE_ENV || 'development' === process.env.NODE_ENV;

export interface AnyObject {
    [key: string]: any;
}

export function formHasErrors(erori: {[x: string]: string[]}){
    const reducer = (accumulator: boolean, currentValue: string[]) => accumulator || (currentValue.length > 0);
    return Object.values(erori).reduce(reducer, false);
}

export function compareObjects<T extends AnyObject>(obj1: T, obj2: T): number {
    let diffCount = 0;

    for (const key in obj1) {
        if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
            if (obj1[key] !== obj2[key]) {
                diffCount++;
            }
        }
    }

    return diffCount;
}


export function formatDate(date: Date) {
    return dayjs(date).format(process.env.REACT_APP_DATE_FORMAT).toString();
};

export function formatDateWithoutTime(date: Date) {
    return dayjs(date).format(process.env.REACT_APP_DATE_FORMAT_WITHOUT_TIME).toString();
};

export function esteDeTipulDate(data: any): data is Date {
    return Object.prototype.toString.call(data) === "[object Date]";
}


export function getEndDayTime(date: Date) : number{
    const endOfDay = date;
    endOfDay.setUTCHours(23, 59, 59, 999);
    const endOfDayTime = endOfDay.getTime() + endOfDay.getTimezoneOffset() * 60000;

    return endOfDayTime;
}

export function getStartDayTime(date: Date) : number{
    const startOfDay = date;
    startOfDay.setUTCHours(0, 0, 0, 1);
    const startOfDayTime = startOfDay.getTime() + startOfDay.getTimezoneOffset() * 60000;
    return startOfDayTime;
}

export function getOneMonthAgo() : Date {
    const auxDate = new Date();
    auxDate.setMonth(auxDate.getMonth() - 1);
    return auxDate;
}

export function getNextMonth() : Date {
    let today = new Date();
    let month = today.getMonth();
    let nextMonth = month === 11 ? 0 : month + 1;

    let nextMonthDate = new Date();
    nextMonthDate.setDate(nextMonth);

    return nextMonthDate;
}

export function getMonthName(dateString: string) : string {
    // Split the date string into day, month (numeric), and year components
    const [day, monthNumeric, year] = dateString.split('-').map(Number);

    // Create a Date object with the numeric month (0-based)
    const date = new Date(year, monthNumeric - 1);

    // Get the month name as a three-letter string (e.g., "Jun")
    const monthName = date.toLocaleString('en-US', { month: 'short' });

    return monthName;
}

export function isDate(obj: any): obj is Date {
    return obj instanceof Date;
}

export function isNumber(inputString: string): boolean {
    return /^[\d.]+$/.test(inputString);
}

export function newDateFromString(dateString: string) : Date {
    const myDate = new Date(dateString);

    return new Date(myDate.getTime() + myDate.getTimezoneOffset() * 60 * 1000);
}