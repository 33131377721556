import {DataTable, DataTableFilterMeta} from "primereact/datatable";
import {Column} from "primereact/column";
import {Skeleton} from "primereact/skeleton";
import React, {useContext, useEffect, useState} from "react";
import {classNames} from "primereact/utils";
import {FilterMatchMode} from "primereact/api";
import {ActionLog} from "../../../data/BackendClasses";
import {InputText} from "primereact/inputtext";
import {GlobalContext} from "../../../data/GlobalContext";
import {getJsonFromBackend} from "../../../data/network";
import {formatDate} from "../../../lib/helpers";
import {Tooltip} from "primereact/tooltip";


export default function Supervising() {

    const tableClassNames = classNames('w-full !rounded-md', {});
    // const rowClass = (data: any) => {return {'!bg-transparent': true};};
    const rowClass = (data: any) => {return {'!bg-transparent': false};};

    const [first, setFirst] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [filters, setFilters] = useState<DataTableFilterMeta>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS}
    });
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');

    const [actionLogs, setActionLogs] = useState<ActionLog[]>([]);
    const [actionLogsLoaded, setActionLogsLoaded] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);

    const {showToastMessage} = useContext(GlobalContext);


    useEffect(() => {
        getJsonFromBackend("/logs/get")
            .then((rsp: ActionLog[]) => {
                setActionLogs(rsp);
                setActionLogsLoaded(true);
            })
            .catch(err => {
                showToastMessage("ERROR", "Error getting action logs data", "error");
                setActionLogsLoaded(true);
                console.log(err)
            })
    }, [reload])

    const renderHeader = () => {
        return (
            <div className="flex flex-row justify-content-end bg-white">
                <span className="p-input-icon-left">
                    <i className="fa fa-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    function renderDateColumn(actionLog: ActionLog){
        return formatDate(actionLog.date);
    }

    function renderError(actionLog: ActionLog){
        return actionLog.successful ?
            <i className={`fa-regular fa-circle-check text-green-700`}/>
            :
            <>
                <i className={`fa-regular fa-circle-xmark text-red-700 action-log-error-target-${actionLog.id} hover:cursor-pointer`}/>
                <Tooltip target={`.action-log-error-target-${actionLog.id}`}>
                    <span>{actionLog.error}</span>
                </Tooltip>
            </>
    }


    return <div className="flex gap-1 mx-auto pt-8 pb-4 px-4 w-full h-full" id="supervising-page">

        {/*<div className="flex flex-col h-fit !rounded-lg  p-1 grow">*/}
        <div className="flex flex-col h-fit !rounded-lgp-1 grow">
            {actionLogsLoaded ? <><DataTable
                stripedRows={false}
                filters= {filters}
                value={actionLogs}
                paginator
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} -> {last} of {totalRecords}"
                rowsPerPageOptions={[5, 10, 25, 50]}
                first={first}
                rows={pageSize}
                totalRecords={actionLogs.length}
                tableStyle={{ minWidth: '50rem'}}
                rowClassName={rowClass}
                className={tableClassNames}
                globalFilterFields= {['action', 'username', 'conversationId']}
                header= {renderHeader()}
                emptyMessage= "No users found."
                sortField="date"
                sortOrder={-1}
                pt={{
                    header: {className: "! !border-0 !rounded-t-md !bg-white"},
                }}
            >
                <Column className="! " sortable field="username" header="Username" pt={{headerTitle: {className: "!font-bold"}}}/>
                <Column className="! " sortable field="action" header="Action" pt={{headerTitle: {className: "!font-bold"}}}/>
                <Column className="! " sortable field="conversationId" header="Conversation ID" pt={{headerTitle: {className: "!font-bold"}}}/>
                <Column className="! " sortable field="successful" body={renderError} header="Successfull" pt={{headerTitle: {className: "!font-bold"}}}/>
                <Column className="! " sortable field="date" body={renderDateColumn} header="Date" pt={{headerTitle: {className: "!font-bold"}}}/>
            </DataTable>

            </> : <Skeleton className="h-full"/>}
        </div>

    </div>

}