import {useContext, useEffect, useState} from "react";
import {AvailableAndSelectedHotelsB24Info, HotelB24Info} from "../../data/BackendClasses";
import {getJsonFromBackend, postJsonToBackend} from "../../data/network";
import {GlobalContext} from "../../data/GlobalContext";
import {PickList, PickListChangeEvent} from "primereact/picklist";


export default function LowInventoryScanner() {

    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [dataModified, setDataModified] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);

    const [availableHotels, setAvailableHotels] = useState<HotelB24Info[]>([])
    const [selectedHotels, setSelectedHotels] = useState<HotelB24Info[]>([])

    const {showToastMessage} = useContext(GlobalContext);


    const itemTemplate = (item: HotelB24Info) => {
        return (
            <div className="flex flex-wrap p-2 align-items-center gap-3">
                {item.hotelName}
            </div>
        );
    };


    useEffect(() => {

        getJsonFromBackend("/hotel-b24-info/low-inventory-scanner")
            .then((rsp: AvailableAndSelectedHotelsB24Info) => {
                setAvailableHotels(rsp.available);
                setSelectedHotels(rsp.selected);
                setDataLoaded(true);
            })
            .catch(err => {
                showToastMessage("Error", "Error getting data", "error");
                console.log(err);
            })

    }, [reload]);

    function onPicklistChange(event: PickListChangeEvent) {
        setDataModified(true);
        setSelectedHotels(event.target);
        setAvailableHotels(event.source);
    };

    function saveSelection(e: any){
        e.preventDefault();

        postJsonToBackend("/low-inventory-scanner/save-selection", selectedHotels)
            .then(rsp => {
                showToastMessage("Success", "Hotels saved", "success");
                setReload(!reload);
            })
            .catch(err => {
                showToastMessage("Error", "Error saving the selected hotels", "error");
                console.log(err);
            })
    }

    return <div id="low-inventory-scanner-page" className="h-full py-20 px-5">

        {dataLoaded && <div className="h-full flex flex-col">
            <div className="grow">
            <PickList
                dataKey="id"
                source={availableHotels}
                target={selectedHotels}
                onChange={onPicklistChange}
                itemTemplate={itemTemplate}
                filter filterBy="hotelName"
                breakpoint="1280px"
                sourceHeader="Available"
                targetHeader="Selected"
                sourceFilterPlaceholder="Search by name"
                targetFilterPlaceholder="Search by name"
                showSourceControls={false}
                showTargetControls={false}
                className="flex-1 h-full"
                pt={{
                    root: {className: "!min-h-full"},
                    list: {className: "!min-h-[60vh]"},
                    header: {className: "!bg-white"},
                    moveToTargetButton: {root: {className: "!bg-[#576f75] hover:!bg-[#485e63] !border-none"}},
                    moveToSourceButton: {root: {className: "!bg-[#576f75] hover:!bg-[#485e63] !border-none"}},
                    moveAllToTargetButton: {root: {className: "!bg-[#576f75] hover:!bg-[#485e63] !border-none"}},
                    moveAllToSourceButton: {root: {className: "!bg-[#576f75] hover:!bg-[#485e63] !border-none"}},
                }}
            />
            </div>
            <div>
                {dataModified && <>
                    <div className="mt-5 text-center">
                        <button
                            className="border p-1 px-2 rounded bg-[#576f75] hover:bg-[#485e63] text-white"
                            onClick={(e) => saveSelection(e)}
                        >
                            SAVE
                        </button>
                    </div>
                </>}
            </div>
        </div>}


    </div>

}