import {DataTable, DataTableFilterMeta} from "primereact/datatable";
import {Column} from "primereact/column";
import {classNames} from "primereact/utils";
import React, {useContext, useEffect, useState} from 'react';
import "./ManagementUsers.scss";
import AddUserComponent from "./AddUserComponent";
import {getJsonFromBackend, postJsonToBackend} from "../../../data/network";
import {AppUser, AppUserWithoutHotels} from "../../../data/BackendClasses";
import ManagementUserRoles from "./ManagementUserRoles";
import ManagementUserPassword from "./ManagementUserPassword";
import {Skeleton} from "primereact/skeleton";
import ManagementUserHotels from "./ManagementUserHotels";
import {GlobalContext} from "../../../data/GlobalContext";
import {FilterMatchMode} from "primereact/api";
import {InputText} from "primereact/inputtext";
import UserInfoDialog from "./UserInfoDialog";

export default function ManagementUsers() {

    const tableClassNames = classNames('w-full !rounded-md', {});

    const [first, setFirst] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [filters, setFilters] = useState<DataTableFilterMeta>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS}
    });
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');

    const [users, setUsers] = useState<AppUserWithoutHotels[]>([]);
    const [usersLoaded, setUsersLoaded] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);
    const [selectedUserId, setSelectedUserId] = useState<number | undefined>(undefined);

    const [showUserPasswordChange, setShowUserPasswordChange] = useState<boolean>(false);

    const [showUserHotelsPickList, setShowUserHotelsPickList] = useState<boolean>(false);
    const [showUserRolesPickList, setShowUserRolesPickList] = useState<boolean>(false);
    const [showAddComponent, setShowAddComponent] = useState<boolean>(false);

    const [selectedUser, setSelectedUser] = useState<AppUserWithoutHotels|undefined>(undefined);
    const [showUserInfoDialog, setShowUserInfoDialog] = useState<boolean>(false);

    const {showToastMessage} = useContext(GlobalContext);

    useEffect(() => {


        getJsonFromBackend("/users/get")
            .then((rsp: AppUser[]) => {
                console.log("GOT USERS ", rsp);
                setUsers(rsp);
                setUsersLoaded(true);
            })
            .catch(err => {
                showToastMessage("ERROR", "Error getting users data", "error");
                setUsersLoaded(true);
            })
    }, [reload])

    function onPageChanged(e: any) {
        if(pageSize != e.rows)
            setPageSize(e.rows);
        if(first != e.first){
            setFirst(e.first);
        }
        if(page != e.page){
            setPage(e.page)
        }
    }

    const rowClass = (data: any) => {
        return {
            '!bg-transparent': true
        };
    };

    function renderUserRoles(user: AppUser){
        return <div className="gap-2 flex max-w-xs overflow-auto">
            {user.roles.map((role) => {
                // return <span key={role.id} className="bg-white/70 p-2 rounded text-xs">{role.role}</span>
                return <span key={role.id} className="bg-[#CDD7D9] p-2 rounded text-xs">{role.role}</span>
            })}
        </div>
    }

    function showRoles(userId: number) {
        setSelectedUserId(userId);
        setShowUserRolesPickList(true);
    }

    function showInfo(user: AppUserWithoutHotels){
        setSelectedUser(user);
        setShowUserInfoDialog(prevState => true);
    }

    function showPasswordChange(userId: number) {
        setSelectedUserId(userId);
        setShowUserPasswordChange(true);
    }

    function showHotels(userId: number) {
        setSelectedUserId(userId);
        setShowUserHotelsPickList(true);
    }

    async function removeUser(user: AppUserWithoutHotels) {
        if(!window.confirm(`Are you sure you want to remove ${user.fullName}?`)){
            return;
        }
        try {
            await postJsonToBackend("/users/remove", user.id);
            showToastMessage("Success", "User removed", "success");
            setReload(!reload);
        } catch (err){
            showToastMessage("Error", "Something bad happened", "error");
        }

    }

    function generateActionButtons(user: AppUserWithoutHotels) {
        return  <div className="flex w-full text-center gap-2">
            <button
                className="border p-1 px-2 rounded bg-[#576f75] hover:bg-[#485e63] text-white"
                onClick={() => showRoles(user.id)}
            >
                Roles
            </button>

            <button
                className="border p-1 px-2 rounded bg-[#576f75] hover:bg-[#485e63] text-white"
                onClick={() => showInfo(user)}
            >
                Info
            </button>

            <button
                className="border p-1 px-2 rounded bg-[#576f75] hover:bg-[#485e63] text-white"
                onClick={() => showPasswordChange(user.id)}
            >
                Password
            </button>

            <button
                className="border p-1 px-2 rounded bg-[#576f75] hover:bg-[#485e63] text-white"
                onClick={() => showHotels(user.id)}
            >
                Hotels
            </button>

            <button
                className="border p-1 px-2 rounded bg-[#576f75] hover:bg-[#485e63] text-white"
                onClick={() => removeUser(user)}
            >
                <i className="fa-solid fa-trash text-red-500 mr-1"/>
                Delete
            </button>
        </div>
    }

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex flex-row justify-content-end">
                <span className="p-input-icon-left">
                    <i className="fa fa-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search by full name or username" />
                </span>
            </div>
        );
    };

    return <div className="flex gap-1 mx-auto pt-8 pb-4 px-4 w-full h-full" id="management-users-page">

        <div className="flex flex-col h-fit !rounded-lg bg-white/70 p-1 grow">
            {usersLoaded ? <><DataTable
                stripedRows={true}
                filters= {filters}
                value={users}
                paginator
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} -> {last} of {totalRecords}"
                rowsPerPageOptions={[5, 10, 25, 50]}
                first={first}
                rows={pageSize}
                totalRecords={users.length}
                tableStyle={{ minWidth: '50rem'}}
                rowClassName={rowClass}
                className={tableClassNames}
                globalFilterFields= {['fullName', 'username']}
                header= {renderHeader()}
                emptyMessage= "No users found."
                sortField={"username"}
                sortOrder={1}
                pt={{
                    header: {className: "!bg-white/70 !border-0 !rounded-t-md focus:!shadow-none"},
                    paginator: {
                        root: {className: "!bg-white/70 !text-[#644243]"},
                        current: {className: "!text-[#4f959d]"},
                        nextPageButton: {className: "!text-[#4f959d] focus:!shadow-none"},
                        lastPageButton: {className: "!text-[#4f959d] focus:!shadow-none"},
                        prevPageButton: {className: "!text-[#4f959d] focus:!shadow-none"},
                        firstPageButton: {className: "!text-[#4f959d] focus:!shadow-none"},
                        RPPDropdown: {
                            root: {className: "focus:!shadow-none"},
                            input: {className: "focus:!shadow-none !text-[#4f959d]"},
                            select: {className: "focus:!shadow-none !text-[#4f959d]"},
                            trigger: {className: "focus:!shadow-none !text-[#4f959d]"},
                        }
                    }
                }}
            >
                <Column className="!bg-white/70 " sortable field="fullName" header="Full Name"/>
                <Column className="!bg-white/70 " sortable field="username" header="Username"/>
                {/*<Column className="!bg-white/70 " body={renderUserRoles} header="Role" pt={{headerTitle: {className: "w-full text-center"}}}/>*/}
                <Column className="!bg-white/70 " body={generateActionButtons} header="Action"/>
            </DataTable>

            </> : <Skeleton className="h-72"/>}
        </div>

        {selectedUserId && showUserRolesPickList && <ManagementUserRoles
            showUserRolesPickList={showUserRolesPickList}
            setShowUserRolesPickList={setShowUserRolesPickList}
            selectedUserId={selectedUserId}
            onClose={() => {
                setSelectedUserId(undefined);
                setShowUserRolesPickList(false);
                setReload(!reload);
                // window.location.reload();
            }}
        />}

        {selectedUserId && showUserPasswordChange && <ManagementUserPassword
            onClose={() => {
                setSelectedUserId(undefined);
                setShowUserPasswordChange(false);
            }}
            selectedUserId={selectedUserId}
            setShowUserPasswordChange={setShowUserPasswordChange}
            showUserPasswordChange={showUserPasswordChange}
        />}

        {selectedUserId && showUserHotelsPickList && <ManagementUserHotels
            showUserHotelsPickList={showUserHotelsPickList}
            setShowUserHotelsPickList={setShowUserHotelsPickList}
            selectedUserId={selectedUserId}
            onClose={() => {
                setSelectedUserId(undefined);
                setShowUserHotelsPickList(false);
                setReload(!reload);
            }}
        />}

        {selectedUser && showUserInfoDialog && <UserInfoDialog
            showUserInfoDialog={showUserInfoDialog}
            onHide={() => {
                setSelectedUser(prevState => undefined);
                setShowUserInfoDialog(prevState => false);
            }}
            selectedUser={selectedUser}
            afterUpdate={newInfo => {
                setUsers(
                    users.map(user => {
                        if(user.id == selectedUser?.id)
                            user = {...user, info: newInfo};
                        return user;
                    })
                )
                setSelectedUser({...selectedUser, info: newInfo})
            }}

        />}

        <div className={`${showAddComponent ? "hidden" : ""}`}>
            <i
                className="fa-solid fa-plus fa-2x mx-2 text-green-900 hover:text-green-700 cursor-pointer"
                onClick={() => setShowAddComponent(true)}
            />
        </div>

        <div className={`flex flex-col w-1/4 gap-2 transition-all duration-500 ${showAddComponent ? "" : "hidden"} transition-all duration-500`}>
            <AddUserComponent afterAdd={() => setReload(!reload)} hideComponent={() => setShowAddComponent(false)}/>
        </div>
    </div>
}