import {classNames} from "primereact/utils";
import React, {useContext, useEffect, useRef, useState} from "react";
import {DataTable} from "primereact/datatable";
import {ModifyCalendarLog, PrettyChange, PrettyHotelChangeRequest, Role} from "../../../data/BackendClasses";
import {GlobalContext} from "../../../data/GlobalContext";
import {createPaginationParams, getJsonFromBackend} from "../../../data/network";
import ReactJson from "@microlink/react-json-view";
import {formatDate, getEndDayTime, getStartDayTime} from "../../../lib/helpers";
import {Column} from "primereact/column";
import {Paginator} from "primereact/paginator";
import {Skeleton} from "primereact/skeleton";
import {Dialog} from "primereact/dialog";
import {Calendar, CalendarChangeEvent} from "primereact/calendar";
import {useCurrentUser} from "../../../data/user";


export default function ModifyCalendarLogsPage() {

    const currentUser = useCurrentUser();

    const tableClassNames = classNames('w-full !rounded-md', {});
    const rowClass = (data: any) => {
        return {
            '!bg-transparent': true
        };
    };

    // @ts-ignore
    const dt = useRef<DataTable>(null);

    const [first, setFirst] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(0);

    const [data, setData] = useState<ModifyCalendarLog[]>([]);
    const [totalElements, setTotalElements] = useState<number>(0);

    const [loading, setLoading] = useState<boolean>(false);

    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);

    const {showToastMessage} = useContext(GlobalContext);

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [dialogContent, setDialogContent] = useState<any>("");
    const [dialogHeader, setDialogHeader] = useState<string>("");


    const [dirty, setDirty] = useState<boolean>(false);
    const [filterDate, setFilterDate] = useState<string | Date | Date[] | undefined | null>(undefined);
    const [filterUsername, setFilterUsername] = useState<string | undefined>((currentUser.roles.includes(Role.ROCKETSTAY_LOGS) && !currentUser.roles.includes(Role.ADMIN)) ? currentUser.username : undefined);


    useEffect(() => {

        setLoading(prevState => true);

        getJsonFromBackend("/cloudbeds-calendar-logs/get" + createPaginationParams(page, pageSize) + createFilterParams(filterDate, filterUsername))
            .then((rsp) => {
                setTotalElements(rsp.totalElements)
                setData(rsp.content);
                setDataLoaded(true);
                setLoading(prevState => false);
            })
            .catch(err => {
                showToastMessage("ERROR", "Error getting logs data", "error");
                setDataLoaded(true);
                setLoading(prevState => false);
                console.log(err)
            })
    }, [reload, page, pageSize])



    function createFilterParams(filterDate: string | Date | Date[] | undefined | null, filterUsername: string | undefined){

        let filterParams = "";

        if(filterDate && filterDate instanceof Date){
            console.log("TYPEOF FILTER DATE IS DATE ", filterDate)
            filterParams = filterParams.concat(`&startDate=${getStartDayTime(filterDate)}`).concat(`&endDate=${getEndDayTime(filterDate)}`);
        }

        if(filterUsername){
            console.log("FILTER BY USERNAME ", filterUsername)
            filterParams = filterParams.concat("&username="+filterUsername)
        }

        return filterParams;
    }

    function onPageChanged(e: any) {
        if(pageSize != e.rows)
            setPageSize(e.rows);
        if(first != e.first){
            setFirst(e.first);
        }
        if(page != e.page){
            setPage(e.page)
        }
    }

    const exportCSV = (selectionOnly: boolean) => {
        dt.current.exportCSV({ selectionOnly });
    };


    function renderLogsData(data: ModifyCalendarLog){
        return <div
            className="max-w-sm overflow-hidden overflow-ellipsis hover:cursor-pointer"
            onClick={() => {
                try {
                    showCustomDialog(
                        `Request (${data.id})`,
                        <ReactJson src={JSON.parse(data.requestData.replace(/'/g, '"').replaceAll("True", "true").replaceAll("False", "false"))}/>
                    )
                } catch (err){
                    showCustomDialog(
                        `Request (${data.id})`,
                        data.requestData
                    )
                }
            }}
        >
            <span className="whitespace-nowrap">{data.requestData}</span>
        </div>
    }

    // function renderPrettyLogsData(data: ModifyCalendarLog){
    //     return <div
    //         className="max-w-sm overflow-hidden overflow-ellipsis hover:cursor-pointer"
    //         onClick={() => {
    //             try {
    //                 showCustomDialog(
    //                     `Request (${data.id})`,
    //                     <ReactJson src={JSON.parse(data.prettyRequestData.replace(/'/g, '"').replaceAll("True", "true").replaceAll("False", "false"))}/>
    //                 )
    //             } catch (err){
    //                 showCustomDialog(
    //                     `Request (${data.id})`,
    //                     data.prettyRequestData
    //                 )
    //             }
    //         }}
    //     >
    //         <span className="whitespace-nowrap">{data.prettyRequestData}</span>
    //     </div>
    // }

    function renderPrettyLogsData(data: ModifyCalendarLog){
        return <div
            className="max-w-sm overflow-hidden overflow-ellipsis hover:cursor-pointer"
            onClick={() => {
                if(data.prettyRequestData){
                    try {
                        const prettyRequestData: PrettyHotelChangeRequest = JSON.parse(data.prettyRequestData.replace(/'/g, '"').replaceAll("True", "true").replaceAll("False", "false"));
                        console.log("PRETTY REQUEST DATA ", prettyRequestData)

                        showCustomDialog(
                            `${data.id} - ${prettyRequestData.propertyName} (${formatDate(data.date)})`,
                            renderPrettyRequestData(prettyRequestData)
                        )


                    } catch (err){
                        showCustomDialog(
                            `Request (${data.id})`,
                            data.prettyRequestData
                        )
                    }
                } else {
                    showCustomDialog(
                        `Request (${data.id})`,
                        data.requestData
                    )
                }
            }}
        >
            <button className="text-white py-2 px-3 bg-[#576f75] rounded hover:text-white hover:bg-[#485e63] mt-auto">View</button>
        </div>
    }

    function renderPrettyRequestData(prettyRequestData: PrettyHotelChangeRequest){

        const changes : Map<string, PrettyChange[]> = convertToMap(prettyRequestData.changes);

        console.log("MY CHANGES ", changes);

        return <div className="flex flex-col">
            {/*<div className="font-semibold text-lg">{prettyRequestData.propertyName}</div>*/}
            <div>{renderChangesMap(changes)}</div>
        </div>
    }

    function convertToMap(obj: {[p: string]: PrettyChange[]}): Map<string, PrettyChange[]> {
        const map = new Map<string, PrettyChange[]>();
        for (const key in obj) {
            if (Object.hasOwnProperty.call(obj, key)) {
                map.set(key, obj[key]);
            }
        }
        return map;
    }

    function renderChangesMap(changes : Map<string, PrettyChange[]>) {
        return <div className="flex flex-col">
            {Array.from(changes.entries()).map(([key, value]) => (
                    <div className="flex flex-col" key={key}>
                        <span className="font-semibold text-blue-500">{key}</span>
                        {value.map(prettyChange => <span className="ml-2">&#x2022; <span className="font-semibold text-blue-500">{prettyChange.whatChanged}</span> was changed into <span className="font-semibold text-blue-500">{prettyChange.value}</span> for date <span className="font-semibold text-blue-500">{prettyChange.date}</span> </span>)}
                    </div>
                ))}
        </div>
    }



    function renderResponse(data: ModifyCalendarLog){
        return <div
            className="max-w-sm overflow-hidden overflow-ellipsis hover:cursor-pointer"
            // onClick={() => showCustomDialog(`Logs (${data.id})`, data.serializedTransaction)}
            onClick={() => {
                try {
                    showCustomDialog(
                        `Response (${data.id})`,
                        <ReactJson src={JSON.parse(data.response.replace(/'/g, '"').replaceAll("True", "true").replaceAll("False", "false"))}/>
                    )
                } catch (err){
                    showCustomDialog(
                        `Response (${data.id})`,
                        data.response
                    )
                }
            }}
        >
            <span className="whitespace-nowrap">{data.response}</span>
        </div>
    }

    function renderError(data: ModifyCalendarLog){
        return <div
            className="max-w-sm overflow-hidden overflow-ellipsis hover:cursor-pointer"
            // onClick={() => showCustomDialog(`Logs (${data.id})`, data.serializedTransaction)}
            onClick={() => {
                try {
                    showCustomDialog(
                        `Error (${data.id})`,
                        <ReactJson src={JSON.parse(data.error.replace(/'/g, '"').replaceAll("True", "true").replaceAll("False", "false"))}/>
                    )
                } catch (err){
                    showCustomDialog(
                        `Error (${data.id})`,
                        data.error
                    )
                }
            }}
        >
            <span className="whitespace-nowrap">{data.error}</span>
        </div>
    }

    function renderDateColumn(data: ModifyCalendarLog){
        return formatDate(data.date);
    }

    function showCustomDialog(header: string, content: any){
        setDialogContent(content);
        setDialogHeader(header);
        setShowDialog(true);
    }

    function hideDialog(){
        setDialogContent("");
        setDialogHeader("");
        setShowDialog(false);
    }

    function changeFilterDate(e: CalendarChangeEvent) {
        setFilterDate(prevValue => e.value);
        setDirty(prevState => true);
    }

    const renderHeader = () => {
        return (
            <div className="flex flex-row justify-between gap-2">

                <div className="flex flex-col w-1/6">
                    <label className="font-medium text-[#576f75]" htmlFor="filter-date">Filter By Date</label>
                    <Calendar
                        id="calendar-24h"
                        value={filterDate}
                        onChange={(e) => changeFilterDate(e)}
                        showIcon
                        showButtonBar
                        view="date"
                        pt={{
                            day:{className: "!m-[1px] !p-[1px]"},
                            container: {className: "!p-[2px]"},
                            dropdownButton: {
                                // root: {className: "!bg-black !border-black focus:!shadow-none"}
                                root: {className: "!bg-[#576f75] !border-[#576f75] focus:!shadow-none"}
                            }
                        }}
                    />
                </div>

                <div className="flex flex-col w-1/6">
                    <label className="font-medium text-[#576f75]" htmlFor="filter-username">Filter By Username</label>
                    <input
                        type="text"
                        className="custom-form mt-auto"
                        value={filterUsername}
                        onChange={(e) => {
                            setFilterUsername(e.target.value);
                            setDirty(prevState => true);
                        }}
                        disabled={(currentUser.roles.includes(Role.ROCKETSTAY_LOGS) && !currentUser.roles.includes(Role.ADMIN))}
                    />
                </div>

                {
                    dirty && <button
                    className="text-white py-2 px-3 bg-blue-500 border border-blue-500 rounded hover:text-white hover:bg-blue-600 hover:border-blue-600 mt-auto"
                    onClick={() => {setReload(prevState => !prevState); setDirty(prevState => false)}}
                    >
                        Filter
                    </button>
                }


                <div className="flex align-items-center justify-content-end gap-2 ml-auto pt-5">
                    <button
                        className="border border-transparent font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 p-2 mt-auto"
                        onClick={() => exportCSV(false)}
                    >
                        <i className="fa-solid fa-file-csv fa-xl"/>
                    </button>

                </div>

            </div>
        );
    }

    return <div className="flex gap-1 mx-auto pt-8 pb-4 px-4 w-full h-full" id="drop-down-page">
        <div className="flex flex-col h-fit !rounded-lg bg-white p-1 grow">
            {dataLoaded ? <>
                <DataTable
                    ref={dt}
                    value={data}
                    first={first}
                    rows={pageSize}
                    totalRecords={totalElements}
                    stripedRows
                    loading={loading}
                    emptyMessage= "No data found."
                    tableStyle={{ minWidth: '50rem' }}
                    rowClassName={rowClass}
                    className={tableClassNames}
                    pt={{
                        header: {className: "!bg-white !border-0 !rounded-t-md"}
                    }}
                    header= {renderHeader()}
                >

                    <Column
                        className="!bg-white "
                        field="id"
                        header="ID"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />
                    <Column
                        className="!bg-white "
                        field="username"
                        header="Username"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="date"
                        header="Date"
                        body={renderDateColumn}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                        dataType="date"
                    />

                    <Column
                        className="!bg-white "
                        field="prettyRequestData"
                        header="Changes"
                        body={renderPrettyLogsData}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    {/*<Column*/}
                    {/*    className="!bg-white "*/}
                    {/*    field="response"*/}
                    {/*    header="Response"*/}
                    {/*    body={renderResponse}*/}
                    {/*    pt={{*/}
                    {/*        headerTitle: {className: "!font-bold"}*/}
                    {/*    }}*/}
                    {/*/>*/}

                    <Column
                        className="!bg-white "
                        field="error"
                        header="Error"
                        body={renderError}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />



                </DataTable>
                <Paginator
                    first={first}
                    rows={pageSize}
                    totalRecords={totalElements}
                    onPageChange={onPageChanged}
                    template="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} -> {last} of {totalRecords}"
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    pt={{
                        root: {className: "!bg-white/70 !text-[#644243] !border-0"},
                        current: {className: "!text-[#4f959d] !border-0"},
                        nextPageButton: {className: "!text-[#4f959d] focus:!shadow-none !border-0"},
                        lastPageButton: {className: "!text-[#4f959d] focus:!shadow-none !border-0"},
                        prevPageButton: {className: "!text-[#4f959d] focus:!shadow-none !border-0"},
                        firstPageButton: {className: "!text-[#4f959d] focus:!shadow-none !border-0"},
                        RPPDropdown: {
                            root: {className: "focus:!shadow-none !border-0"},
                            input: {className: "focus:!shadow-none !text-[#4f959d]"},
                            select: {className: "focus:!shadow-none !text-[#4f959d]"},
                            trigger: {className: "focus:!shadow-none !text-[#4f959d]"},
                        }
                    }}
                />
            </> : <Skeleton className="h-72"/>}
        </div>


        <Dialog header={dialogHeader} visible={showDialog} style={{ width: '50vw' }} onHide={hideDialog}>
            <p className="m-0">
                {dialogContent}
            </p>
        </Dialog>

    </div>

}