import {useFormState} from "../../../data/Form";
import {postJsonToBackend} from "../../../data/network";
import {formHasErrors, isNumber} from "../../../lib/helpers";
import React, {useContext} from "react";
import {isReturnedErrors} from "../../../components/ErrorModal";
import {GlobalContext} from "../../../data/GlobalContext";
import {Calendar} from "primereact/calendar";

const initialState = {
    hotelName: '',
    hotelProfile: '',
    propertyId: '',
    category: '',
    tax: '',
    taxPercent: '',
    taxPerNight: '',
    taxPerNightPercent: '',
    airbnbCom: '',
    rocketstayShare: '',
    resortFee: '',
    resortFeePercent: '',
    passThrough: '',
    lodgingTaxDaily: ''
};
export default function AddHotelComponent({afterAdd, hideComponent}:{afterAdd: () => void, hideComponent: () => void}) {

    const {showToastMessage} = useContext(GlobalContext);

    const {inputValues, handleInputChange, resetInputValues, dirty} = useFormState(initialState)
    const erori: { [x: string]: string[] } = {
        hotelName: [],
        hotelProfile: [],
        propertyId: [],
        category: [],
        tax: [],
        taxPercent: [],
        taxPerNight: [],
        taxPerNightPercent: [],
        airbnbCom: [],
        rocketstayShare: [],
        resortFee: [],
        resortFeePercent: [],
        passThrough: [],
        lodgingTaxDaily: []
    };


    async function addHotel(e: any) {
        e.preventDefault();
        // await postJsonToBackend("/hotels/add", {hotelName: inputValues.hotelName, hotelProfile: inputValues.hotelProfile, propertyId: inputValues.propertyId});
        await postJsonToBackend("/hotels/add", inputValues).then(rsp => {
            resetInputValues(initialState);
            afterAdd();
        }).catch(err => {
            console.log("ERR ", err);
            if(isReturnedErrors(err)){
                showToastMessage("Error", err.errors[0].message, "error");
            } else {
                showToastMessage("Error", "Someting went bat", "error");
            }
        });

    }

    function checkForErrors() {
        if (!inputValues.hotelName && dirty) {
            erori.hotelName.push("Hotel name is mandatory");
        }

        if(inputValues.propertyId && !/^\d+$/.test(inputValues.propertyId)){
            erori.propertyId.push("Property Id should be a number");
        }

        if(dirty && !(inputValues.propertyId.length > 0 || inputValues.hotelProfile.length > 0)){
            erori.propertyId.push("At least one of this fields is mandatory");
            erori.hotelProfile.push("At least one of this fields is mandatory");
        }

        if(dirty && (inputValues.category != '') && !isNumber(inputValues.category)){
            erori.category.push("Category should be a numeric value");
        }

        if(dirty && (inputValues.tax != '') && !isNumber(inputValues.tax)){
            erori.tax.push("Tax should be a numeric value");
        }

        if(dirty && (inputValues.taxPercent != '') && !isNumber(inputValues.taxPercent)){
            erori.taxPercent.push("Tax % should be a numeric value");
        }

        if(dirty && (inputValues.taxPerNight != '') && !isNumber(inputValues.taxPerNight)){
            erori.tax.push("Tax/Night should be a numeric value");
        }

        if(dirty && (inputValues.taxPerNightPercent != '') && !isNumber(inputValues.taxPerNightPercent)){
            erori.taxPercent.push("Tax/Night % should be a numeric value");
        }

        if(dirty && (inputValues.airbnbCom != '') && !isNumber(inputValues.airbnbCom)){
            erori.airbnbCom.push("Airbnb Com should be a numeric value");
        }

        if(dirty && (inputValues.rocketstayShare != '') && !isNumber(inputValues.rocketstayShare)){
            erori.rocketstayShare.push("Rocketstay Share should be a numeric value");
        }

        if(dirty && (inputValues.resortFee != '') && !isNumber(inputValues.resortFee)){
            erori.resortFee.push("Resort Fee should be a numeric value");
        }

        if(dirty && (inputValues.resortFeePercent != '') && !isNumber(inputValues.resortFeePercent)){
            erori.resortFeePercent.push("Resort Fee % should be a numeric value");
        }

        if(dirty && (inputValues.passThrough != '') && !isNumber(inputValues.passThrough)){
            erori.passThrough.push("Pass Through should be a numeric value");
        }

        if(dirty && (inputValues.lodgingTaxDaily != '') && !isNumber(inputValues.lodgingTaxDaily)){
            erori.lodgingTaxDaily.push("Lodging Tax Daily should be a numeric value");
        }
    }

    checkForErrors();


    return <>
        <div className="shadow-md bg-white/70 rounded-lg flex flex-col">
            <div className="px-6 py-4 bg-white/50 flex rounded-t-lg font-bold text-md">
                <div>New Hotel</div>
                <div className="ml-auto">
                    <i
                        className="fa-regular fa-circle-xmark fa-lg text-red-900 hover:text-red-700 cursor-pointer"
                        onClick={(e) => hideComponent()}
                    />
                </div>
            </div>
            <div className="flex flex-col py-4 px-6">
                <form onSubmit={addHotel}>
                    <div className="mb-2">
                        <label className={`block mb-2 font-medium ${erori.hotelName.length > 0 ? "text-red-500" : ""}`} htmlFor="hotelName">Full Name</label>
                        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.hotelName.length > 0 ? " border-solid border-red-500" : " border-solid border-gray-200"}`} name="hotelName" id="hotelName" type="text" value={inputValues.hotelName} onChange={handleInputChange}/>
                        {erori.hotelName.length > 0 && <div className="text-red-500 text-sm">{erori.hotelName[erori.hotelName.length-1]}</div>}
                    </div>

                    <div className="mb-2">
                        <label className={`block mb-2 font-medium ${erori.hotelProfile.length > 0 ? "text-red-500" : ""}`} htmlFor="hotelProfile">Hotel Profile</label>
                        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.hotelProfile.length > 0 ? " border-solid border-red-500" : " border-solid border-gray-200"}`} name="hotelProfile" id="hotelProfile" type="text" value={inputValues.hotelProfile} onChange={handleInputChange}/>
                        {erori.hotelProfile.length > 0 && <div className="text-red-500 text-sm">{erori.hotelProfile[erori.hotelProfile.length-1]}</div>}
                    </div>

                    <div className="mb-2">
                        <label className={`block mb-2 font-medium ${erori.propertyId.length > 0 ? "text-red-500" : ""}`} htmlFor="propertyId">Property ID</label>
                        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.propertyId.length > 0 ? " border-solid border-red-500" : " border-solid border-gray-200"}`} name="propertyId" id="propertyId" type="text" value={inputValues.propertyId} onChange={handleInputChange}/>
                        {erori.propertyId.length > 0 && <div className="text-red-500 text-sm">{erori.propertyId[erori.propertyId.length-1]}</div>}
                    </div>

                    <div className="mb-2">
                        <label className={`block mb-2 font-medium ${erori.category.length > 0 ? "text-red-500" : ""}`} htmlFor="category">Category</label>
                        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.category.length > 0 ? " border-solid border-red-500" : " border-solid border-gray-200"}`} name="category" id="category" type="text" value={inputValues.category} onChange={handleInputChange}/>
                        {erori.category.length > 0 && <div className="text-red-500 text-sm">{erori.category[erori.category.length-1]}</div>}
                    </div>

                    <div className="flex flex-row gap-2">
                        {/*<div className="mb-2">*/}
                        {/*    <label className={`block mb-2 font-medium ${erori.tax.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="tax">Tax</label>*/}
                        {/*    <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.tax.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="tax" id="tax" type="text" value={inputValues.tax} onChange={handleInputChange}/>*/}
                        {/*    {erori.tax.length > 0 && <div className="text-red-500 text-sm">{erori.tax[erori.tax.length-1]}</div>}*/}
                        {/*</div>*/}
                        <div className="mb-2">
                            <label className={`block mb-2 font-medium ${erori.taxPercent.length > 0 ? "text-red-500" : ""}`} htmlFor="taxPercent">Tax %</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.taxPercent.length > 0 ? " border-solid border-red-500" : " border-solid border-gray-200"}`} name="taxPercent" id="taxPercent" type="text" value={inputValues.taxPercent} onChange={handleInputChange}/>
                            {erori.taxPercent.length > 0 && <div className="text-red-500 text-sm">{erori.taxPercent[erori.taxPercent.length-1]}</div>}
                        </div>

                        <div className="mb-2">
                            <label className={`block mb-2 font-medium ${erori.taxPerNight.length > 0 ? "text-red-500" : ""}`} htmlFor="taxPerNight">Hotel Tax/Night</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.taxPerNight.length > 0 ? " border-solid border-red-500" : " border-solid border-gray-200"}`} name="taxPerNight" id="taxPerNight" type="text" value={inputValues.taxPerNight} onChange={handleInputChange}/>
                            {erori.taxPerNight.length > 0 && <div className="text-red-500 text-sm">{erori.taxPerNight[erori.taxPerNight.length-1]}</div>}
                        </div>

                    </div>

                    {/*<div className="flex flex-row gap-2">*/}
                    {/*    <div className="mb-2">*/}
                    {/*        <label className={`block mb-2 font-medium ${erori.taxPerNightPercent.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="taxPerNightPercent">Tax/Night %</label>*/}
                    {/*        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.taxPerNightPercent.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="taxPerNightPercent" id="taxPerNightPercent" type="text" value={inputValues.taxPerNightPercent} onChange={handleInputChange}/>*/}
                    {/*        {erori.taxPerNightPercent.length > 0 && <div className="text-red-500 text-sm">{erori.taxPerNightPercent[erori.taxPerNightPercent.length-1]}</div>}*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="flex flex-row gap-2">
                        <div className="mb-2">
                            <label className={`block mb-2 font-medium ${erori.airbnbCom.length > 0 ? "text-red-500" : ""}`} htmlFor="airbnbCom">Airbnb Com %</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.airbnbCom.length > 0 ? " border-solid border-red-500" : " border-solid border-gray-200"}`} name="airbnbCom" id="airbnbCom" type="text" value={inputValues.airbnbCom} onChange={handleInputChange}/>
                            {erori.airbnbCom.length > 0 && <div className="text-red-500 text-sm">{erori.airbnbCom[erori.airbnbCom.length-1]}</div>}
                        </div>


                        <div className="mb-2">
                            <label className={`block mb-2 font-medium ${erori.rocketstayShare.length > 0 ? "text-red-500" : "border-solid border-gray-200"}`} htmlFor="rocketstayShare">Rocketstay Share %</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.rocketstayShare.length > 0 ? " border-solid border-red-500" : " border-solid border-gray-200"}`} name="rocketstayShare" id="rocketstayShare" type="text" value={inputValues.rocketstayShare} onChange={handleInputChange}/>
                            {erori.rocketstayShare.length > 0 && <div className="text-red-500 text-sm">{erori.rocketstayShare[erori.rocketstayShare.length-1]}</div>}
                        </div>
                    </div>

                    {/*<div className="flex flex-row gap-2">*/}
                    {/*    <div className="mb-2">*/}
                    {/*        <label className={`block mb-2 font-medium ${erori.resortFee.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="resortFee">Resort Fee</label>*/}
                    {/*        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.resortFee.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="resortFee" id="resortFee" type="text" value={inputValues.resortFee} onChange={handleInputChange}/>*/}
                    {/*        {erori.resortFee.length > 0 && <div className="text-red-500 text-sm">{erori.resortFee[erori.resortFee.length-1]}</div>}*/}
                    {/*    </div>*/}

                    {/*    <div className="mb-2">*/}
                    {/*        <label className={`block mb-2 font-medium ${erori.resortFeePercent.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="resortFeePercent">Resort Fee %</label>*/}
                    {/*        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.resortFeePercent.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="resortFeePercent" id="resortFeePercent" type="text" value={inputValues.resortFeePercent} onChange={handleInputChange}/>*/}
                    {/*        {erori.resortFeePercent.length > 0 && <div className="text-red-500 text-sm">{erori.resortFeePercent[erori.resortFeePercent.length-1]}</div>}*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="flex flex-row gap-2">
                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.passThrough.length > 0 ? "text-red-500" : ""}`} htmlFor="passThrough">Pass Through % <br/>(rocket fee)</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.passThrough.length > 0 ? " border-solid border-red-500" : " border-solid border-gray-200"}`} name="passThrough" id="passThrough" type="text" value={inputValues.passThrough} onChange={handleInputChange}/>
                            {erori.passThrough.length > 0 && <div className="text-red-500 text-sm">{erori.passThrough[erori.passThrough.length-1]}</div>}
                        </div>

                        <div className="mb-2 relative w-1/2">
                            <label className={`block mb-2 font-medium ${erori.lodgingTaxDaily.length > 0 ? "text-red-500" : ""}`} htmlFor="lodgingTaxDaily">Lodging Tax Daily</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white absolute bottom-0 ${erori.lodgingTaxDaily.length > 0 ? " border-solid border-red-500" : " border-solid border-gray-200"}`} name="lodgingTaxDaily" id="lodgingTaxDaily" type="text" value={inputValues.lodgingTaxDaily} onChange={handleInputChange}/>
                            {erori.lodgingTaxDaily.length > 0 && <div className="text-red-500 text-sm">{erori.lodgingTaxDaily[erori.lodgingTaxDaily.length-1]}</div>}
                        </div>
                    </div>


                    <div className="w-full text-center">
                        {!formHasErrors(erori) && dirty && <button
                            type="submit"
                            className="border p-1 px-2 rounded bg-[#576f75] hover:bg-[#485e63] text-white"
                        >
                            Add
                        </button>}
                    </div>
                </form>
            </div>
        </div>
    </>
}