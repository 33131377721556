import {Calendar, CalendarChangeEvent} from "primereact/calendar";
import React, {useContext, useEffect, useRef, useState} from "react";
import {createQueryString, getJsonFromBackend} from "../../../data/network";
import {Booking, CloudbedsHotel, Role} from "../../../data/BackendClasses";
import {GlobalContext} from "../../../data/GlobalContext";
import {esteDeTipulDate} from "../../../lib/helpers";
import {isReturnedErrors} from "../../../components/ErrorModal";
import {DataTable, DataTableFilterMeta} from "primereact/datatable";
import {Column, ColumnFilterElementTemplateOptions} from "primereact/column";
import BookingDetailsDialog from "./BookingDetailsDialog";
import {FilterMatchMode} from "primereact/api";
import {InputText} from "primereact/inputtext";
import {Panel} from "primereact/panel";
import {Skeleton} from "primereact/skeleton";
import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import {Link} from "react-router-dom";
import {useCurrentUser} from "../../../data/user";

export default function CloudbedsBookingsPage() {

    const [hotels, setHotels] = useState<CloudbedsHotel[]>([]);

    const [data, setData] = useState<Booking[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [first, setFirst] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [loading, setLoading] = useState<boolean>(false);

    const [startDate, setStartDate] = useState<string | Date | Date[] | undefined | null>(new Date());
    const [endDate, setEndDate] = useState<string | Date | Date[] | undefined | null>(getNextMonth());
    const [filterType, setFilterType] = useState<string>('checkingIn');
    const [selectedHotel, setSelectedHotel] = useState<number>(0);

    const [dirty, setDirty] = useState<boolean>(false);
    const [showFilters, setShowFilters] = useState<boolean>(true);
    const {showToastMessage} = useContext(GlobalContext);

    const [showDetailsDialog, setShowDetailsDialog] = useState<boolean>(false);
    const [selectedBooking, setSelectedBooking] = useState<Booking|undefined>(undefined);

    const [filters, setFilters] = useState<DataTableFilterMeta>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS},
        status: {value: null, matchMode: FilterMatchMode.EQUALS}
    });
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');
    const [statusFilterValue, setStatusFilterValue] = useState<string|undefined>(undefined);

    const currentUser = useCurrentUser();

    // @ts-ignore
    const dt = useRef<DataTable>(null);

    useEffect(() => {
        getJsonFromBackend("/cloudbeds-calendar/hotels/get-my-hotels-with-property-id")
            .then((rsp: CloudbedsHotel[]) => {
                setHotels(rsp);
                if(rsp.length == 1){
                    setSelectedHotel(rsp[0].id);
                    setEndDate(getNextMonth())
                    getBookingsData(rsp[0].id);
                }
            })
            .catch(err => {
                showToastMessage("Error", "Error getting the data about the hotels", "error");
                console.log(err);
            })
    }, [])

    function changeStartDate(e: CalendarChangeEvent) {
        setStartDate(prevValue => e.value);
        setDirty(prevState => true);
    }

    function changeEndDate(e: CalendarChangeEvent) {
        setEndDate(prevValue => e.value);
        setDirty(prevState => true);
    }

    function handleHotelChanged(e: any){
        setSelectedHotel(prevState => e.target.value);
        setDirty(prevState => true);
    }

    function getNextMonth() : Date{
        const nextMonth = new Date();
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        return nextMonth;
    }

    function getBookingsData(hotelId : number | undefined){

        const queryParamsObject = {
            hotelId: hotelId ? hotelId : selectedHotel,
            start_date: esteDeTipulDate(startDate) ? startDate.getTime() : null,
            end_date:  hotelId ? getNextMonth().getTime() : esteDeTipulDate(endDate) ? endDate.getTime() : null,
            filterType: filterType
        }

        setLoading(prevState => true);
        setDataLoaded(prevState => false);

        getJsonFromBackend("/cloudbeds-bookings/get?"+createQueryString(queryParamsObject))
            .then((res : Booking[]) => {

                console.log("res ", res);

                setLoading(prevState => false);
                setData(prevState => res);
                setDataLoaded(prevState => true);

            })
            .catch(err => {
                console.log("ERR ", err);
                setLoading(prevState => false);
                if(isReturnedErrors(err)){
                    showToastMessage("Error", err.errors[0].message, "error");
                } else {
                    showToastMessage("Error", "Error getting the data", "error");
                }
                setDirty(prevState => true);
            })

        setDirty(prevState => false);
    }

    function renderGuest(data: Booking) {
        return <div className="flex flex-col">
            <div
                className="text-[#4f959d] font-medium hover:cursor-pointer hover:underline"
                onClick={() => {
                    setSelectedBooking(prevState => data);
                    setShowDetailsDialog(prevState => true);
                }}
            >
                {data.firstName} {data.lastName}
            </div>
            <div className="text-xs">Adults: {data.numAdult}</div>
            {(data.numChild > 0) && <div className="text-xs">Children: {data.numChild}</div>}
        </div>
    }

    function renderStatus(data: Booking) {
        return <span>
            {data.status.toUpperCase()}
        </span>
    }

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    function onStatusFilterChanged(e: DropdownChangeEvent) {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['status'].value = value;

        setFilters(_filters);
        setStatusFilterValue(value);
    }

    const exportCSV = (selectionOnly: boolean) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const selectedValueTemplate = (option: string|undefined, props:any) => {
        if (option) {
            return (
                option.toUpperCase()
            );
        }

        return <span>{props.placeholder}</span>;
    };


    const renderHeader = () => {
        return (

            <div className="flex flex-row w-full gap-4 bg-white min-w-fit justify-between">

                {dataLoaded && data.length > 0 && <div className="flex flex-row gap-2">
                    <span className="p-input-icon-left mt-auto">
                        <i className="fa fa-search" />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                    </span>

                    <span className="p-input-icon-left mt-auto">
                        <Dropdown showClear placeholder={"Status"} value={statusFilterValue} onChange={onStatusFilterChanged} valueTemplate={selectedValueTemplate} itemTemplate={value => value.toUpperCase()} options={["confirmed", "cancelled"]}/>
                    </span>

                </div>}

                <div className="pt-2 mt-auto">
                    <button
                        className="border border-transparent font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 p-2"
                        onClick={() => exportCSV(false)}
                    >
                        <i className="fa-solid fa-file-csv fa-xl"/>
                    </button>
                </div>
            </div>
        );
    };

    function renderPanelHeader() {
        return <div className="flex flex-row !w-full gap-3">
            <span className="text-xl font-bold">Reservations</span>

            {(currentUser.roles.includes(Role.ROCKETSTAY_CALENDAR) || currentUser.roles.includes(Role.ROCKETSTAY_MASTER)) && <>
                <span className="text-xl">
                /
                </span>
                <Link to={"/cloudbeds-calendar"}>
                    <span className="text-xl hover:text-[#4f959d]">Calendar</span>
                </Link>
            </>}

            {(currentUser.roles.includes(Role.ROCKETSTAY_REPORTS) || currentUser.roles.includes(Role.ROCKETSTAY_MASTER)) && <>
                <span className="text-xl">
                    /
                </span>

                <Link to={"/cloudbeds-reports"}>
                    <span className="text-xl hover:text-[#4f959d]">Reports</span>
                </Link>
            </>}
        </div>
    }


    return <div className="flex flex-col min-h-full cloudbeds-bookings-page overflow-x-scroll min-w-full px-10">

        <Panel
            header={renderPanelHeader()}
            toggleable
            className="mt-2"
            pt={{
                header: {className: "!bg-white"}
            }}

        >
            <div className="flex flex-row w-full gap-4 bg-white min-w-fit">

                <div className="flex flex-col w-1/6">
                    <label className="font-medium" htmlFor="selected-hotel">Hotel</label>
                    {(hotels && hotels.length > 0) ?
                        <select
                            className={`custom-form rounded-lg block 2.5`}
                            name="selected-hotel"
                            id="selected-hotel"
                            value={selectedHotel}
                            onChange={handleHotelChanged}
                        >
                            <option key={0} value={0}>Select a hotel</option>
                            {hotels.map(hotel => <option key={hotel.id} value={hotel.id}>{hotel.name}</option>)}

                        </select>
                        :
                        <Skeleton
                        pt={{root: {className: "!h-full"}}}
                        />}
                </div>

                <div className="flex flex-col w-1/6">
                    <label className="font-medium" htmlFor="booking-type">Type</label>
                    <select
                        name="booking-type"
                        className="custom-form"
                        value={filterType}
                        onChange={(e) => {setFilterType(e.target.value); setDirty(prevState => true)}}
                    >
                        <option value="checkingIn">Check in</option>
                        <option value="checkingOut">Check out</option>
                    </select>
                </div>

                <div className="flex flex-col w-1/6">
                    <label className="font-medium" htmlFor="start-date">Start Date</label>
                    <Calendar
                        id="calendar-24h"
                        value={startDate}
                        onChange={(e) => changeStartDate(e)}
                        showIcon
                        showButtonBar
                        view="date"
                        pt={{
                            day:{className: "!m-[1px] !p-[1px]"},
                            container: {className: "!p-[2px]"},
                            dropdownButton: {
                                root: {className: "!bg-[#576f75] !border-[#576f75] focus:!shadow-none"}
                            }
                        }}
                    />
                </div>

                <div className="flex flex-col w-1/6">
                    <label className="font-medium" htmlFor="start-date">End Date</label>
                    <Calendar
                        id="calendar-24h"
                        value={endDate}
                        onChange={(e) => changeEndDate(e)}
                        showIcon
                        showButtonBar
                        view="date"
                        pt={{
                            day:{className: "!m-[1px] !p-[1px]"},
                            container: {className: "!p-[2px]"},
                            dropdownButton: {
                                root: {className: "!bg-[#576f75] !border-[#576f75] focus:!shadow-none"}
                            }
                        }}
                    />
                </div>


                {dirty && startDate && selectedHotel != 0 && <button
                    className="text-white py-2 px-3 bg-[#576f75] hover:bg-[#485e63] rounded hover:text-white mt-auto ml-auto"
                    onClick={() => getBookingsData(undefined)}
                >
                    Get Data
                </button>}
            </div>
        </Panel>




        <div className="flex-col border-t-4 rounded mt-4 bg-white border-[#576f75] px-2 shadow-md grow relative">
            {dataLoaded && <DataTable
                ref={dt}
                stripedRows={true}
                value={data}
                sortField="arrival"
                sortOrder={1}
                filters= {filters}
                loading={loading}
                paginator
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} -> {last} of {totalRecords}"
                rowsPerPageOptions={[5, 10, 25, 50]}
                first={first}
                rows={pageSize}
                totalRecords={data.length}
                tableStyle={{ minWidth: '50rem'}}
                globalFilterFields= {['roomId', 'id', 'firstName', 'lastName', 'arrival', 'departure', 'status']}
                emptyMessage= "No data found."
                pt={{
                    header: {className: "!bg-white/70 !border-0 !rounded-t-md focus:!shadow-none"},
                    paginator: {
                        root: {className: "!bg-white/70 !text-[#644243] !border-0"},
                        current: {className: "!text-[#4f959d] !border-0"},
                        nextPageButton: {className: "!text-[#4f959d] focus:!shadow-none !border-0"},
                        lastPageButton: {className: "!text-[#4f959d] focus:!shadow-none !border-0"},
                        prevPageButton: {className: "!text-[#4f959d] focus:!shadow-none !border-0"},
                        firstPageButton: {className: "!text-[#4f959d] focus:!shadow-none !border-0"},
                        RPPDropdown: {
                            root: {className: "focus:!shadow-none !border-0"},
                            input: {className: "focus:!shadow-none !text-[#4f959d]"},
                            select: {className: "focus:!shadow-none !text-[#4f959d]"},
                            trigger: {className: "focus:!shadow-none !text-[#4f959d]"},
                        }
                    }
                }}
                header={dataLoaded && data.length > 0 ? renderHeader : ""}
            >
                <Column
                    sortable
                    field="firstName"
                    header="Guest"
                    body={renderGuest}
                    pt={{headerTitle: {className: "!font-bold"}}}
                />

                <Column
                    sortable
                    field="lastName"
                    header="Last Name"
                    filter={false}
                    body={(data) => {return <></>}}
                    pt={{
                        headerTitle: {className: "!font-bold"},
                        root: {className: "!hidden"}
                    }}
                />

                <Column
                    sortable
                    field="roomId"
                    header="Room"
                    pt={{headerTitle: {className: "!font-bold"}}}
                />
                <Column
                    sortable
                    field="id"
                    header="Reservation"
                    pt={{headerTitle: {className: "!font-bold"}}}
                />

                <Column
                    sortable
                    field="apiReference"
                    header="Channel ID"
                    pt={{headerTitle: {className: "!font-bold"}}}
                />

                <Column
                    sortable
                    field="referer"
                    header="Channel"
                    pt={{headerTitle: {className: "!font-bold"}}}
                />

                <Column
                    sortable
                    field="arrival"
                    header="Arrival"
                    pt={{headerTitle: {className: "!font-bold"}}}
                />
                <Column
                    sortable
                    field="departure"
                    header="Departure"
                    pt={{headerTitle: {className: "!font-bold"}}}
                />
                <Column
                    sortable
                    field="status"
                    header="Status"
                    filter
                    body={renderStatus}
                    showFilterMenu={false}
                    pt={{
                        headerTitle: {className: "!font-bold"},
                    }}
                />
            </DataTable>}

            {loading && <i className="fa-solid fa-spinner absolute top-1/2 left-1/2 fa-xl -translate-x-1/2 -translate-y-1/2 fa-spin"/>}
        </div>

        {showDetailsDialog && selectedBooking && <BookingDetailsDialog
            showDialog={showDetailsDialog}
            booking={selectedBooking}
            onHide={() => {setShowDetailsDialog(() => false); setSelectedBooking(() => undefined)}}
        />}

     </div>
}